import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { authApi } from '../../store/api/authApi';
import { Backdrop, CircularProgress, Toolbar, Typography } from '@mui/material';
import { useAppDispatch } from '../../store/hooks';
import { login } from '../../store/slices/authSlice';
import PublicHeader from '../../components/header/PublicHeader';
import { Box } from '@mui/system';

const Authentication: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [loginUser, { isLoading, isError }] = authApi.useLoginMutation();

  const code = searchParams.get('code');

  const firstRender = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true;
      if (code) {
        loginUser({ code })
          .unwrap()
          .then((result) => dispatch(login(result.token)));
      } else {
        window.location.href = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&response_type=${process.env.REACT_APP_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=${process.env.REACT_APP_SCOPE}`;
      }
    }
  }, [code, dispatch, loginUser]);

  return (
    <>
      {isLoading && (
        <Backdrop open className='global-backdrop-loader'>
          <CircularProgress />
        </Backdrop>
      )}
      {isError && (
        <>
          <PublicHeader />
          <Box component="main">
            <Toolbar />
            <Box className="main-container no-access">
              <Typography>
                You don’t seem to have the rights to access this application.
                Please contact your administrator to check your rights and
                possibly grant you access
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Authentication;
