import React, { FC } from 'react';
import { Grid, Paper } from '@mui/material';
import RangeDatePicker from '../common/datePicker/RangeDatePicker';
import { Option } from '../../utils/constants/option';
import MultipleSelect from '../common/select/MultipleSelect';
import { comparableOptions } from '../../utils/constants/filtersOptions/comparable';
import Button from '../common/button/Button';
import { filtersOptionsApi } from '../../store/api/filtersOptionsApi';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  Filters,
  selectSelectedFilters,
  updateFilters,
} from '../../store/slices/filtersSlice';
import { format } from 'date-fns';
import { resetNewForecast } from '../../store/slices/newForecastSlice';
import {
  selectFirstSearch,
  selectIsSearching,
  updateFirstSearch,
} from '../../store/slices/searchButtonSlice';
import { resetResortCount } from '../../store/slices/resortCountSlice';

interface FiltersProps {
  collapse: () => void;
}

const Filter: FC<FiltersProps> = ({ collapse }) => {
  const dispatch = useAppDispatch();
  const selectedFilters = useAppSelector(selectSelectedFilters);
  const firstSearch = useAppSelector(selectFirstSearch);
  const isSearching = useAppSelector(selectIsSearching);

  const { data: armZoneOptions, isLoading: isLoadingArmZone } =
    filtersOptionsApi.useGetArmZoneOptionsQuery(null);
  const { data: brandOptions, isLoading: isLoadingBrand } =
    filtersOptionsApi.useGetBrandOptionsQuery(null);
  const { data: businessUnitOptions, isLoading: isLoadingBusinessUnit } =
    filtersOptionsApi.useGetBusinessUnitOptionsQuery(null);
  const { data: countryOptions, isLoading: isLoadingCountry } =
    filtersOptionsApi.useGetCountryOptionsQuery(null);
  const { data: drmZoneOptions, isLoading: isLoadingDrmZone } =
    filtersOptionsApi.useGetDrmZoneOptionsQuery(null);
  const { data: legalStatusOptions, isLoading: isLoadingLegalStatus } =
    filtersOptionsApi.useGetLegalStatusOptionsQuery(null);
  const { data: resortOptions, isLoading: isLoadingResort } =
    filtersOptionsApi.useGetResortOptionsQuery(null);

  const [filters, setFilters] = React.useState<Filters>(selectedFilters);

  const onChangeDates = (newDates: [Date | null, Date | null]) => {
    const [startDate, endDate] = newDates;
    const startISODate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
    const endISODate = endDate ? format(endDate, 'yyyy-MM-dd') : null;
    setFilters({ ...filters, startDate: startISODate, endDate: endISODate });
  };

  const onChangeFilters = (newValue: Option[], id: string) => {
    setFilters({ ...filters, [id]: newValue });
  };

  const onSearch = () => {
    if (
      JSON.stringify(selectedFilters) !== JSON.stringify(filters) ||
      firstSearch
    ) {
      dispatch(updateFirstSearch(false));
      dispatch(resetResortCount());
      dispatch(resetNewForecast());
      dispatch(updateFilters(filters));
      collapse();
    }
  };

  const onResetFilters = () => {
    setFilters({
      ...filters,
      armZones: [],
      brands: [],
      businessUnits: [],
      drmZones: [],
      comparableBudget: [],
      comparableNX: [],
      countries: [],
      legalStatuses: [],
      resorts: [],
    });
  };

  return (
    <Paper elevation={0} className="paper filter-paper">
      <Grid container>
        <Grid item container spacing={1.5} xs={12}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <RangeDatePicker
              label="Date range"
              startDate={filters.startDate ? new Date(filters.startDate) : null}
              endDate={filters.endDate ? new Date(filters.endDate) : null}
              onChange={onChangeDates}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="businessUnits"
              label="Business unit"
              options={businessUnitOptions ? businessUnitOptions : []}
              value={filters.businessUnits}
              onChange={onChangeFilters}
              isLoading={isLoadingBusinessUnit}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="armZones"
              label="Arm zone"
              options={armZoneOptions ? armZoneOptions : []}
              value={filters.armZones}
              onChange={onChangeFilters}
              isLoading={isLoadingArmZone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="brands"
              label="Brand"
              options={brandOptions ? brandOptions : []}
              value={filters.brands}
              onChange={onChangeFilters}
              isLoading={isLoadingBrand}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="comparableBudget"
              label="Comparable budget"
              options={comparableOptions}
              value={filters.comparableBudget}
              onChange={onChangeFilters}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="resorts"
              label="Resort"
              options={resortOptions ? resortOptions : []}
              value={filters.resorts}
              onChange={onChangeFilters}
              isLoading={isLoadingResort}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="countries"
              label="Country"
              options={countryOptions ? countryOptions : []}
              value={filters.countries}
              onChange={onChangeFilters}
              isLoading={isLoadingCountry}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="drmZones"
              label="Drm zone"
              options={drmZoneOptions ? drmZoneOptions : []}
              value={filters.drmZones}
              onChange={onChangeFilters}
              isLoading={isLoadingDrmZone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="legalStatuses"
              label="Legal status"
              options={legalStatusOptions ? legalStatusOptions : []}
              value={filters.legalStatuses}
              onChange={onChangeFilters}
              isLoading={isLoadingLegalStatus}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <MultipleSelect
              id="comparableNX"
              label="Duetto Y/N"
              options={comparableOptions}
              value={filters.comparableNX}
              onChange={onChangeFilters}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1.5}
          xs={12}
          justifyContent="space-evenly"
          className="paper-action"
        >
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Button
              label="Reset filters"
              variant="outlined"
              color="info"
              onClick={onResetFilters}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} xl={2}>
            <Button
              label="Search"
              variant="contained"
              onClick={onSearch}
              isLoading={isSearching}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Filter;
