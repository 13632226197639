import React, { FC } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '../../common/button/Button';
import { filtersOptionsApi } from '../../../store/api/filtersOptionsApi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUserIsHeadOf } from '../../../store/slices/authSlice';
import LockBUSwitch from './LockBUSwitch';
import LockAllSwitch from './LockAllSwitch';
import { api } from '../../../store/api';

interface LockBUModalProps {
  open: boolean;
  onClose: () => void;
}

const LockBUModal: FC<LockBUModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const userIsHeadOf = useAppSelector(selectUserIsHeadOf);

  const { data: businessUnitOptions, isLoading } =
    filtersOptionsApi.useGetBusinessUnitOptionsQuery(null);

  const [lockAllUpdated, setLockAllUpdated] = React.useState<boolean>(false);

  const handleLockAllUpdate = () => {
    setLockAllUpdated(true);
  };

  const handleClose = () => {
    if (lockAllUpdated) {
      dispatch(
        api.util.invalidateTags(['TotalForecastSum', 'SegmentForecastSum'])
      );
    }
    setLockAllUpdated(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>Lock BU</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box className="circular-progress-lock-modal">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContentText>Lock a single BU :</DialogContentText>
            {businessUnitOptions &&
              businessUnitOptions.map((businessUnit) => (
                <LockBUSwitch
                  key={businessUnit.id}
                  businessUnit={businessUnit}
                />
              ))}
            {businessUnitOptions && userIsHeadOf && (
              <>
                <DialogContentText marginTop={2}>
                  Lock all BU :
                </DialogContentText>
                <LockAllSwitch
                  isLockAll={businessUnitOptions.some(
                    (businessUnit) => businessUnit.closed
                  )}
                  onChange={handleLockAllUpdate}
                />
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          label="Close"
          variant="contained"
          fullWidth={false}
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default LockBUModal;
