import React, { FC } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, TableCell, Tooltip } from '@mui/material';

interface CollapsibleCellProps {
  label: string;
  collapsible?: boolean;
  rowType: 'total' | 'segment' | 'hotel' | 'day';
  open?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const CollapsibleCell: FC<CollapsibleCellProps> = ({
  label,
  collapsible = false,
  rowType,
  open,
  onClick,
  disabled = false,
}) => {
  return (
    <TableCell className="collapsible-cell">
      {disabled && <LockIcon className="collapsible-icon" />}
      <Box className={`${rowType ? `collapsible-${rowType}` : ''}`}>
        {rowType === 'hotel' && (
          <Tooltip title={label} arrow>
            <Box component="span" className="collapsible-hotel-label">
              {label}
            </Box>
          </Tooltip>
        )}
        {rowType !== 'hotel' && label}
        {collapsible && (
          <IconButton onClick={onClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </Box>
    </TableCell>
  );
};

export default CollapsibleCell;
