import React, { FC } from 'react';
import { Paper, Stack } from '@mui/material';
import HeaderTable from './HeaderTable';
import { roomNightsColumnHeaders } from '../../../utils/constants/columnHeaders/roomNights';
import { adrColumnHeaders } from '../../../utils/constants/columnHeaders/adr';
import { roomRevenueColumnHeaders } from '../../../utils/constants/columnHeaders/roomRevenue';
import { nonRoomRevenueColumnHeaders } from '../../../utils/constants/columnHeaders/nonRoomRevenue';

const HeaderTables: FC = () => {
  return (
    <Stack direction="row" className="sticky-header sticky-column-container">
      <Paper elevation={0} className="paper table corner-table sticky-corner" />
      <HeaderTable
        label="Room Nights"
        columns={roomNightsColumnHeaders}
        tableType="room-nights-table"
      />
      <HeaderTable
        label="ADR"
        columns={adrColumnHeaders}
        tableType="adr-table"
      />
      <HeaderTable
        label="Room Revenue"
        columns={roomRevenueColumnHeaders}
        tableType="room-revenue-table"
      />
      <HeaderTable
        label="Non-Room Revenue"
        columns={nonRoomRevenueColumnHeaders}
        tableType="non-room-revenue-table"
      />
    </Stack>
  );
};

export default HeaderTables;
