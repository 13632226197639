import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { TotalForecastSum } from '../../utils/constants/forecastSum';
import { totalForecastApi } from '../api/totalForecastApi';

interface ParamDates {
  currentParamDate: string | null;
  previousParamDate: string | null;
}

const initialState: ParamDates = {
  currentParamDate: null,
  previousParamDate: null,
};

export const paramDateSlice = createSlice({
  name: 'paramDate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      totalForecastApi.endpoints.getTotalForecastSum.matchFulfilled,
      (state, action: PayloadAction<TotalForecastSum>) => {
        if (action.payload) {
          state.currentParamDate = action.payload.currentParamDate;
          state.previousParamDate = action.payload.previousParamDate;
        }
      }
    );
  },
});

export const selectCurrentParamDate = (state: RootState): string | null => {
  return state.paramDate.currentParamDate;
};

export const selectPreviousParamDate = (state: RootState): string | null => {
  return state.paramDate.previousParamDate;
};
