import React, { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '../button/Button';

interface AlertDialogProps {
  title: string;
  contentText: string;
  open: boolean;
  onClose: () => void;
  onValidate: () => void;
}

const AlertDialog: FC<AlertDialogProps> = ({
  title,
  contentText,
  open,
  onClose,
  onValidate,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          label="Cancel"
          color="error"
          fullWidth={false}
          onClick={onClose}
        />
        <Button
          label="Confirm"
          color="success"
          fullWidth={false}
          onClick={onValidate}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
