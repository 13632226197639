import React, { FC } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { InputAdornment, TextField, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface RangeDatePickerProps {
  label: string;
  startDate: Date | null;
  endDate: Date | null;
  onChange: (newDates: [Date | null, Date | null]) => void;
}

const RangeDatePicker: FC<RangeDatePickerProps> = ({
  label,
  startDate,
  endDate,
  onChange,
}) => {
  const [internalStartDate, setInternalStartDate] = React.useState<Date | null>(
    startDate
  );
  const [internalEndDate, setInternalEndDate] = React.useState<Date | null>(
    endDate
  );
  const onDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setInternalStartDate(start);
    setInternalEndDate(end);
    onChange(dates);
  };

  return (
    <>
      <Typography color="text.secondary">{label}</Typography>
      <DatePicker
        selectsRange
        startDate={internalStartDate}
        endDate={internalEndDate}
        dateFormat="dd/MM/yyyy"
        onChange={(newDates, event) => onDateRangeChange(newDates)}
        placeholderText="Date range..."
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        customInput={
          <TextField
            className="input"
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
            }}
          />
        }
      />
    </>
  );
};

export default RangeDatePicker;
