import { api } from '.';

interface LockAllRequest {
  locked: boolean;
}

interface LockBURequest extends LockAllRequest {
  id: number;
}

export const lockBUApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateBusinessUnits: builder.mutation<null, LockAllRequest>({
      query: (lockAllRequest) => ({
        url: `business-units`,
        method: 'PUT',
        body: lockAllRequest,
      }),
      invalidatesTags: ['BusinessUnitOptions'],
    }),
    updateBusinessUnit: builder.mutation<null, LockBURequest>({
      query: (lockBURequest) => ({
        url: `business-units/${lockBURequest.id}`,
        method: 'PUT',
        body: { locked: lockBURequest.locked },
      }),
      invalidatesTags: ['BusinessUnitOptions'],
    }),
  }),
});
