import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppSelector } from '../store/hooks';
import { selectUserIsConnected } from '../store/slices/authSlice';
import ToastAlertContainer from '../components/alerts/ToastAlertContainer';
import PrivateRoutes from './private.routes';
import PublicRoutes from './public.routes';

const Routes = () => {
  const userIsConnected = useAppSelector(selectUserIsConnected);
  if (userIsConnected) return <PrivateRoutes />;
  return <PublicRoutes />;
};

const Router = () => {
  return (
    <BrowserRouter>
      <ToastAlertContainer />
      <Routes />
    </BrowserRouter>
  );
};

export default Router;
