import React, { FC } from 'react';
import { Box, Toolbar } from '@mui/material';
import Header from '../components/header/Header';
import { Outlet } from 'react-router-dom';

const Layout: FC = () => {
  return (
    <>
      <Header />
      <Box component="main">
        <Toolbar />
        <Box className="main-container">
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
