import React, { FC } from 'react';
import {
  Backdrop,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Results from './Results';
import Button from '../common/button/Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectNewForecast } from '../../store/slices/newForecastSlice';
import {
  selectNumberOfSelectedDays,
  selectSelectedFiltersApiRequest,
} from '../../store/slices/filtersSlice';
import { segmentForecastApi } from '../../store/api/segmentForecastApi';
import { hotelForecastApi } from '../../store/api/hotelForecastApi';
import { dayForecastApi } from '../../store/api/dayForecastApi';
import { toast } from 'react-toastify';
import {
  selectCurrentParamDate,
  selectPreviousParamDate,
} from '../../store/slices/paramDateSlice';
import { format } from 'date-fns';
import AlertDialog from '../common/alertDialog/AlertDialog';
import { resetForecastApi } from '../../store/api/resetForecastApi';
import { api } from '../../store/api';
import { selectResortCount } from '../../store/slices/resortCountSlice';
import WarningIcon from '@mui/icons-material/Warning';

const ResultSection: FC = () => {
  const dispatch = useAppDispatch();
  const resortCount = useAppSelector(selectResortCount);
  const numberOfSelectedDays = useAppSelector(selectNumberOfSelectedDays);
  const newForecast = useAppSelector(selectNewForecast);
  const selectedFiltersApiRequest = useAppSelector(
    selectSelectedFiltersApiRequest
  );
  const currentParamDate = useAppSelector(selectCurrentParamDate);
  const previousParamDate = useAppSelector(selectPreviousParamDate);
  const [updateSegmentsForecast, { isLoading: isLoadingUpdateSegments }] =
    segmentForecastApi.useUpdateSegmentsForecastMutation();
  const [updateHotelsForecast, { isLoading: isLoadingUpdateHotels }] =
    hotelForecastApi.useUpdateHotelsForecastMutation();
  const [updateDaysForecast, { isLoading: isLoadingUpdateDays }] =
    dayForecastApi.useUpdateDaysForecastMutation();
  const [resetForecast, { isLoading: isLoadingResetForecast }] =
    resetForecastApi.useResetForecastMutation();

  const onSave = () => {
    if (
      !newForecast.segments.length &&
      !newForecast.resorts.length &&
      !newForecast.forecasts.length
    ) {
      toast.warning('Nothing to save');
    }
    if (newForecast.segments.length) {
      updateSegmentsForecast({
        filters: selectedFiltersApiRequest,
        segments: newForecast.segments,
      })
        .unwrap()
        .then(() => toast.success('Segments update success'))
        .catch(() => toast.error('Segments update failure'));
    }
    if (newForecast.resorts.length) {
      updateHotelsForecast({
        filters: selectedFiltersApiRequest,
        resorts: newForecast.resorts,
      })
        .unwrap()
        .then(() => toast.success('Hotels update success'))
        .catch(() => toast.error('Hotels update failure'));
    }
    if (newForecast.forecasts.length) {
      updateDaysForecast(newForecast.forecasts)
        .unwrap()
        .then(() => toast.success('Days update success'))
        .catch(() => toast.error('Days update failure'));
    }
  };

  /**
   * Reapplying previous forecast modal action
   */
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const handleOpenModal = () => {
    setOpenDialog(true);
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
  };

  const handleValidateModal = () => {
    setOpenDialog(false);
    resetForecast(selectedFiltersApiRequest)
      .unwrap()
      .then(() => {
        toast.success('Previous forecast successfully reapplied');
        dispatch(
          api.util.invalidateTags(['TotalForecastSum', 'SegmentForecastSum'])
        );
      })
      .catch(() =>
        toast.error('An error occurred while reapplying the previous forecast')
      );
  };

  const warningMessage =
    'A large number of hotels have been selected, this represents a large amount of data and they will not all be directly loaded. The calculated columns may differ slightly when saving.';

  return (
    <>
      {isLoadingResetForecast && (
        <Backdrop open className="global-backdrop-loader">
          <CircularProgress />
        </Backdrop>
      )}
      <Paper elevation={0} className="section-header">
        <Stack direction="row" spacing={4} alignItems="center">
          {resortCount !== null &&
            !(resortCount <= 5 && numberOfSelectedDays <= 31) && (
              <Tooltip title={warningMessage} arrow>
                <WarningIcon color="warning" />
              </Tooltip>
            )}
          <Typography variant="h6" component="h2">
            Results
          </Typography>
          <Typography variant="body2">
            Forecast date:
            {currentParamDate
              ? format(new Date(currentParamDate), ' dd/MM/yyyy')
              : ' '}
          </Typography>
          <Typography variant="body2">
            Previous forecast :
            {previousParamDate
              ? format(new Date(previousParamDate), ' dd/MM/yyyy')
              : ' '}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            label="Reapply previous forecast"
            variant="contained"
            fullWidth={false}
            onClick={handleOpenModal}
          />
          <Button
            label="Save overrides"
            variant="contained"
            fullWidth={false}
            isLoading={
              isLoadingUpdateSegments ||
              isLoadingUpdateHotels ||
              isLoadingUpdateDays
            }
            onClick={onSave}
          />
        </Stack>
      </Paper>
      <Results />

      <AlertDialog
        title="Reapply previous forecast ?"
        contentText='The "Previous forecast" column will be reapplied to the "Last saved forecast" column. This action may take a few minutes.'
        open={openDialog}
        onClose={handleCloseModal}
        onValidate={handleValidateModal}
      />
    </>
  );
};

export default ResultSection;
