import React, { FC } from 'react';
import { ClickAwayListener, Stack, TextField, Typography } from '@mui/material';
import { roundWithPrecision } from '../../utils/functions/round';
import Button from '../common/button/Button';

interface CalculateProps {
  onApply: (newValue: string) => void;
  onClose: () => void;
  currentValue: number;
  unlockedValue: number;
  fixedDaySum: number;
}

const Calculate: FC<CalculateProps> = ({
  onApply,
  onClose,
  currentValue,
  unlockedValue,
  fixedDaySum,
}) => {
  const floatRegExp = new RegExp('^[-]?([0-9]+([.][0-9]*)?)$');
  const [newForecast, setNewForecast] = React.useState<string>('');
  const [change, setChange] = React.useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue === '' || newValue === '-' || floatRegExp.test(newValue)) {
      setNewForecast(newValue);
    }
  };

  React.useEffect(() => {
    const newForecastFloat = parseFloat(newForecast);
    if (!isNaN(newForecastFloat)) {
      let calculatedValue: number;
      calculatedValue = roundWithPrecision(
        ((newForecastFloat - currentValue) /
          roundWithPrecision(unlockedValue - fixedDaySum, 3)) *
          100,
        5
      );
      if (
        calculatedValue !== Infinity &&
        calculatedValue !== -Infinity &&
        !Number.isNaN(calculatedValue)
      ) {
        setChange(calculatedValue.toString());
      }
    } else {
      setChange('');
    }
  }, [currentValue, fixedDaySum, newForecast, unlockedValue]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Stack direction="column" spacing={1} className="calculate">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography>Current New Forecast</Typography>
          <TextField
            className="input calculate-input"
            disabled
            value={roundWithPrecision(currentValue, 3)}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography>Desired New forecast</Typography>
          <TextField
            className="input calculate-input"
            value={newForecast}
            onChange={handleChange}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography>Change (%)</Typography>
          <TextField
            className="input calculate-input"
            disabled
            value={change ?? ''}
          />
        </Stack>
        <Button
          label="Apply"
          variant="contained"
          color="primary"
          onClick={() => onApply(change)}
          disabled={
            change === '' ||
            parseFloat(change) > 10000000000 ||
            parseFloat(change) < -10000000000
          }
        />
      </Stack>
    </ClickAwayListener>
  );
};

export default Calculate;
