import React, { FC } from 'react';
import { Box } from '@mui/material';
import HeaderTables from './headerTables/HeaderTables';
import TotalTables from './totalTables/TotalTables';
import SegmentTables from './segmentTables/SegmentTables';

const Results: FC = () => {
  return (
    <Box className="overflow-container">
      <HeaderTables />
      <TotalTables />
      <SegmentTables />
    </Box>
  );
};

export default Results;
