import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { segmentForecastApi } from '../../../store/api/segmentForecastApi';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedFiltersApiRequest } from '../../../store/slices/filtersSlice';
import { selectResortCount } from '../../../store/slices/resortCountSlice';
import { selectFirstSearch } from '../../../store/slices/searchButtonSlice';
import LoadingRow from '../loadingRow/LoadingRow';
import SegmentTable from './SegmentTable';

const SegmentTables: FC = () => {
  const resortCount = useAppSelector(selectResortCount);
  const selectedFiltersApiRequest = useAppSelector(
    selectSelectedFiltersApiRequest
  );
  const firstSearch = useAppSelector(selectFirstSearch);

  const {
    data: segmentsForecastSum,
    isError,
    isFetching,
  } = segmentForecastApi.useGetSegmentsForecastSumQuery(
    selectedFiltersApiRequest,
    { skip: firstSearch }
  );

  React.useEffect(() => {
    if (isError) {
      toast.error('An error occurred while retrieving segments');
    }
  }, [isError]);

  return (
    <>
      {resortCount === null ? (
        <></>
      ) : isFetching ? (
        <LoadingRow rowType="segment" />
      ) : (
        segmentsForecastSum &&
        segmentsForecastSum.map((segmentForcastSum) => (
          <SegmentTable
            key={segmentForcastSum.segment.id}
            segmentForcastSum={segmentForcastSum}
          />
        ))
      )}
    </>
  );
};

export default SegmentTables;
