import { ColumnHeader } from './columnHeader';

export const adrColumnHeaders: ColumnHeader[] = [
  {
    name: 'ADR 14D',
    tooltip: 'Average daily rate of room nights made in the last 14 days',
  },
  {
    name: 'ADR 7D',
    tooltip: 'Average daily rate of room nights made in the last 7 days',
  },
  {
    name: 'BoB (CY)',
    tooltip: 'Average daily rate of room nights for this date',
  },
  {
    name: 'BoB (nx)',
    tooltip:
      'Average daily rate of room nights at the same date of the reference year at equivalent leadtime',
  },
  {
    name: 'BoB (CY v nx)',
    tooltip: 'Ratio between "BoB (CY)" and BoB (nx)',
  },
  {
    name: 'Final ADR nx',
    tooltip:
      'Average daily rate of room nights at the equivalent date of the reference year',
  },
  {
    name: 'Initial forecast',
    tooltip: 'Predicted value by the algorithm given past values',
  },
  {
    name: 'Last saved forecast',
    tooltip:
      'Last forecast which has been saved by user. Default value = "Initial forecast"',
  },
  {
    name: 'Change',
    tooltip:
      'Modifier applied to "Last saved forecast" values which are not overridden ',
  },
  {
    name: 'New forecast',
    tooltip:
      'Forecasted average daily rate computed with "Change" and "Last Saved forecast"',
  },
  {
    name: 'Previous Forecast',
    tooltip: 'Last run forecasted average daily rate',
  },
  {
    name: 'Budget',
    tooltip:
      'Average daily rate budget. Computed from room sold budget and room revenue ht budget',
  },
  {
    name: 'New Forecast vs Budget',
    tooltip: 'Ratio between "New forecast" and "Budgetu',
  },
];
