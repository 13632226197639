import { api } from '.';
import { DayForecast } from '../../utils/constants/forecastSum';
import { UpdateDayForecast } from '../../utils/constants/updateForecast';
import { FiltersApiRequest } from '../slices/filtersSlice';

interface DayForecastRequest {
  segmentId: number;
  hotelId: number;
  filters: FiltersApiRequest;
}

export const dayForecastApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDaysForecast: builder.query<DayForecast[], DayForecastRequest>({
      query: (dayForecastRequest) => ({
        url: `segments/${dayForecastRequest.segmentId}/resorts/${dayForecastRequest.hotelId}/forecasts/searches`,
        method: 'POST',
        body: dayForecastRequest.filters,
      }),
      keepUnusedDataFor: 0,
    }),
    updateDaysForecast: builder.mutation<null, UpdateDayForecast[]>({
      query: (updateDaysForecast) => ({
        url: 'forecasts',
        method: 'PUT',
        body: updateDaysForecast,
      }),
      invalidatesTags: ['TotalForecastSum', 'SegmentForecastSum'],
    }),
  }),
});
