import { RootState } from '..';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { logout } from '../slices/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const UNAUTHORIZED = 401;
const fetchBaseQueryWithToken: ReturnType<typeof fetchBaseQuery> = async (
  args,
  api,
  extra
) => {
  let result = await baseQuery(args, api, extra);

  if (result.error && result.error.status === UNAUTHORIZED) {
    const token = (api.getState() as RootState).auth.token;
    if (token) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: fetchBaseQueryWithToken,
  endpoints: () => ({}),
  tagTypes: ['TotalForecastSum', 'SegmentForecastSum', 'BusinessUnitOptions'],
});
