import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { hotelForecastApi } from '../../../store/api/hotelForecastApi';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedFiltersApiRequest } from '../../../store/slices/filtersSlice';
import { selectFirstSearch } from '../../../store/slices/searchButtonSlice';
import LoadingRow from '../loadingRow/LoadingRow';
import HotelTable from './HotelTable';

interface HotelTablesProps {
  segmentId: number;
}

const HotelTables: FC<HotelTablesProps> = ({ segmentId }) => {
  const selectedFiltersApiRequest = useAppSelector(
    selectSelectedFiltersApiRequest
  );
  const firstSearch = useAppSelector(selectFirstSearch);

  const {
    data: hotelsForecastSum,
    isError,
    isFetching,
  } = hotelForecastApi.useGetHotelsForecastSumQuery(
    {
      segmentId,
      filters: selectedFiltersApiRequest,
    },
    { skip: firstSearch }
  );

  React.useEffect(() => {
    if (isError) {
      toast.error('An error occurred while retrieving hotels');
    }
  }, [isError]);

  return (
    <>
      {isFetching ? (
        <LoadingRow rowType="hotel" />
      ) : (
        hotelsForecastSum &&
        hotelsForecastSum.map((hotelForecastSum) => (
          <HotelTable
            key={hotelForecastSum.resort.id}
            hotelForcastSum={hotelForecastSum}
          />
        ))
      )}
    </>
  );
};

export default HotelTables;
