import React, { FC } from 'react';
import {
  Paper,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { totalForecastApi } from '../../../store/api/totalForecastApi';
import RoomNightsRow from '../rows/RoomNightsRow';
import AdrRow from '../rows/AdrRow';
import RoomRevenueRow from '../rows/RoomRevenueRow';
import NonRoomRevenueRow from '../rows/NonRoomRevenueRow';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectSelectedFiltersApiRequest } from '../../../store/slices/filtersSlice';
import CollapsibleCell from '../cells/CollapsibleCell';
import {
  selectNewTotalForecast,
  updateNewTotalForecast,
} from '../../../store/slices/newForecastSlice';
import { selectFirstSearch } from '../../../store/slices/searchButtonSlice';
import LoadingRow from '../loadingRow/LoadingRow';
import { toast } from 'react-toastify';

const TotalTables: FC = () => {
  const selectedFiltersApiRequest = useAppSelector(
    selectSelectedFiltersApiRequest
  );
  const firstSearch = useAppSelector(selectFirstSearch);

  const {
    data: totalForecastSum,
    isError,
    isFetching,
  } = totalForecastApi.useGetTotalForecastSumQuery(selectedFiltersApiRequest, {
    skip: firstSearch,
  });

  React.useEffect(() => {
    if (isError) {
      toast.error('An error occurred while retrieving the total');
    }
  }, [isError]);

  const dispatch = useAppDispatch();
  const newTotalForecast = useAppSelector(selectNewTotalForecast);

  const onChange = (
    newValue: number | null,
    target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue'
  ) => {
    dispatch(
      updateNewTotalForecast({
        newValue,
        target,
      })
    );
  };

  return (
    <>
      {isError ? (
        <></>
      ) : isFetching ? (
        <LoadingRow rowType="total" />
      ) : totalForecastSum ? (
        <Stack
          direction="row"
          className={`sticky-column-container ${
            totalForecastSum.locked ? 'disabled' : ''
          }`}
        >
          <TableContainer
            component={Paper}
            elevation={0}
            className="paper table collapsible-table sticky-column"
          >
            <Table>
              <TableHead>
                <TableRow>
                  <CollapsibleCell
                    label="Total"
                    rowType="total"
                    disabled={totalForecastSum.locked}
                  />
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          <TableContainer
            component={Paper}
            elevation={0}
            className="paper table room-nights-table"
          >
            <Table>
              <TableHead>
                <RoomNightsRow
                  roomSold={totalForecastSum.roomSoldSum}
                  rowType="total"
                  newForecast={newTotalForecast}
                  onChange={onChange}
                  disabled={totalForecastSum.locked}
                />
              </TableHead>
            </Table>
          </TableContainer>

          <TableContainer
            component={Paper}
            elevation={0}
            className="paper table adr-table"
          >
            <Table>
              <TableHead>
                <AdrRow
                  adr={totalForecastSum.adrSum}
                  rowType="total"
                  newForecast={newTotalForecast}
                  onChange={onChange}
                  disabled={totalForecastSum.locked}
                />
              </TableHead>
            </Table>
          </TableContainer>

          <TableContainer
            component={Paper}
            elevation={0}
            className="paper table room-revenue-table"
          >
            <Table>
              <TableHead>
                <RoomRevenueRow
                  roomRevenue={totalForecastSum.roomRevenueSum}
                  rowType="total"
                  newForecast={newTotalForecast}
                />
              </TableHead>
            </Table>
          </TableContainer>

          <TableContainer
            component={Paper}
            elevation={0}
            className="paper table non-room-revenue-table"
          >
            <Table>
              <TableHead>
                <NonRoomRevenueRow
                  nonRoomRevenue={totalForecastSum.nonRoomRevenueSum}
                  rowType="total"
                  newForecast={newTotalForecast}
                  onChange={onChange}
                  disabled={totalForecastSum.locked}
                />
              </TableHead>
            </Table>
          </TableContainer>
        </Stack>
      ) : (
        !firstSearch && (
          <Typography className="no-results-data">
            No data for this research
          </Typography>
        )
      )}
    </>
  );
};

export default TotalTables;
