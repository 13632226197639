import React, { FC } from 'react';
import { Stack, Switch, Typography } from '@mui/material';
import { lockBUApi } from '../../../store/api/lockBUApi';
import { toast } from 'react-toastify';

interface LockAllSwitchProps {
  isLockAll: boolean;
  onChange: () => void;
}

const LockAllSwitch: FC<LockAllSwitchProps> = ({ isLockAll, onChange }) => {
  const [updateBusinessUnits] = lockBUApi.useUpdateBusinessUnitsMutation();
  const [checked, setChecked] = React.useState<boolean>(isLockAll);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    updateBusinessUnits({ locked: event.target.checked })
      .unwrap()
      .then(() => {
        onChange();
        toast.success('Business units lock status update success');
      })
      .catch(() => toast.error('Business units lock status update failure'));
  };

  React.useEffect(() => {
    setChecked(isLockAll);
  }, [isLockAll]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography>Lock all</Typography>
      <Switch
        className="switch-header"
        checked={checked}
        onChange={handleChange}
      />
    </Stack>
  );
};

export default LockAllSwitch;
