import React, { FC } from 'react';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';

const PublicHeader: FC = () => {
  return (
    <AppBar className="header">
      <Toolbar>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" component="h1">
              Forecasting Tool
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default PublicHeader;
