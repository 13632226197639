import React, { FC } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { Option } from '../../../utils/constants/option';

interface MultipleSelectProps {
  id: string;
  label: string;
  options: Option[];
  value: Option[];
  onChange: (newValue: Option[], id: string) => void;
  isLoading?: boolean;
}

const MultipleSelect: FC<MultipleSelectProps> = ({
  id,
  label,
  options,
  value,
  onChange,
  isLoading = false,
}) => {
  return (
    <>
      <Typography color="text.secondary">{label}</Typography>
      <Autocomplete
        id={id}
        multiple
        disableCloseOnSelect
        value={value}
        onChange={(event, newValue) => onChange(newValue, id)}
        options={options}
        getOptionLabel={(option) => option.name}
        renderTags={(tagValue, getTagProps) => {
          if (tagValue.length === 1) {
            return <Typography>{tagValue[0].name}</Typography>;
          }
          if (tagValue.length === options.length) {
            return <Typography>All</Typography>;
          }
          if (tagValue.length > 1) {
            return <Typography>{tagValue.length} Selected values</Typography>;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            className="input"
            fullWidth
          />
        )}
        loading={isLoading}
      />
    </>
  );
};

export default MultipleSelect;
