import React, { FC } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Stack, Switch, Tooltip, Typography } from '@mui/material';
import { BusinessUnitOption } from '../../../utils/constants/option';
import { lockBUApi } from '../../../store/api/lockBUApi';
import { useAppSelector } from '../../../store/hooks';
import { selectUserIsHeadOf } from '../../../store/slices/authSlice';
import { toast } from 'react-toastify';

interface LockBUModalProps {
  businessUnit: BusinessUnitOption;
}

const LockBUSwitch: FC<LockBUModalProps> = ({ businessUnit }) => {
  const userIsHeadOf = useAppSelector(selectUserIsHeadOf);
  const [updateBusinessUnit] = lockBUApi.useUpdateBusinessUnitMutation();

  const [checked, setChecked] = React.useState<boolean>(
    userIsHeadOf
      ? businessUnit.lockByHead
      : businessUnit.lockByHead || businessUnit.lockByDrm
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    updateBusinessUnit({ id: businessUnit.id, locked: event.target.checked })
      .unwrap()
      .then(() => toast.success('Business unit lock status update success'))
      .catch(() => toast.error('Business unit lock status update failure'));
  };

  React.useEffect(() => {
    setChecked(
      userIsHeadOf
        ? businessUnit.lockByHead
        : businessUnit.lockByHead || businessUnit.lockByDrm
    );
  }, [businessUnit.lockByDrm, businessUnit.lockByHead, userIsHeadOf]);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography>{businessUnit.name}</Typography>
      <Stack direction="row" alignItems="center">
        {businessUnit.closed && (
          <Tooltip title="This BU is closed" placement="right" arrow>
            <WarningIcon color="error" />
          </Tooltip>
        )}
        {!businessUnit.closed && businessUnit.lockByHead && !userIsHeadOf && (
          <Tooltip title="This BU is lock by Head" placement="right" arrow>
            <WarningIcon color="error" />
          </Tooltip>
        )}
        <Switch
          className="switch-header"
          checked={checked}
          onChange={handleChange}
          disabled={
            businessUnit.closed || (businessUnit.lockByHead && !userIsHeadOf)
          }
        />
      </Stack>
    </Stack>
  );
};

export default LockBUSwitch;
