import React, { FC } from 'react';
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColumnHeader } from '../../../utils/constants/columnHeaders/columnHeader';

interface HeaderTableProps {
  label: string;
  columns: ColumnHeader[];
  tableType:
    | 'room-nights-table'
    | 'adr-table'
    | 'room-revenue-table'
    | 'non-room-revenue-table';
}

const HeaderTable: FC<HeaderTableProps> = ({ label, columns, tableType }) => {
  const { t } = useTranslation();

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={`paper table ${tableType}`}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={columns.length}>
              {t(label)}
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                align="center"
                className={column.name === 'Change' ? 'change-cell' : ''}
              >
                <Tooltip title={t(column.tooltip)} placement="top" arrow>
                  <Box component="span">{t(column.name)}</Box>
                </Tooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default HeaderTable;
