import { api } from '.';
import { SegmentForecastSum } from '../../utils/constants/forecastSum';
import { UpdateSegmentForecast } from '../../utils/constants/updateForecast';
import { FiltersApiRequest } from '../slices/filtersSlice';

interface UpdateSegmentsForecast {
  filters: FiltersApiRequest;
  segments: UpdateSegmentForecast[];
}

export const segmentForecastApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSegmentsForecastSum: builder.query<
      SegmentForecastSum[],
      FiltersApiRequest
    >({
      query: (filters) => ({
        url: 'segments/forecasts/sums',
        method: 'POST',
        body: filters,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['SegmentForecastSum'],
    }),
    updateSegmentsForecast: builder.mutation<null, UpdateSegmentsForecast>({
      query: (updateSegmentsForecast) => ({
        url: 'segments/forecasts',
        method: 'PUT',
        body: {
          filters: updateSegmentsForecast.filters,
          segments: updateSegmentsForecast.segments,
        },
      }),
      invalidatesTags: ['TotalForecastSum', 'SegmentForecastSum'],
    }),
  }),
});
