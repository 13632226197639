import { configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import { filtersSlice } from './slices/filtersSlice';
import { searchButtonSlice } from './slices/searchButtonSlice';
import { newForecastSlice } from './slices/newForecastSlice';
import { moneySlice } from './slices/moneySlice';
import { authSlice } from './slices/authSlice';
import { paramDateSlice } from './slices/paramDateSlice';
import { resortCountSlice } from './slices/resortCountSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [filtersSlice.name]: filtersSlice.reducer,
    [moneySlice.name]: moneySlice.reducer,
    [newForecastSlice.name]: newForecastSlice.reducer,
    [searchButtonSlice.name]: searchButtonSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [paramDateSlice.name]: paramDateSlice.reducer,
    [resortCountSlice.name]: resortCountSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
