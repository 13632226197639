import { ColumnHeader } from './columnHeader';

export const roomNightsColumnHeaders: ColumnHeader[] = [
  {
    name: 'Pick-up 14D',
    tooltip: 'Number of room nights made in the last 14 days',
  },
  {
    name: 'Pick-up 7D',
    tooltip: 'Number of room nights made in the last 7 days',
  },
  {
    name: 'BoB (CY)',
    tooltip: 'Current number of room nights for this date',
  },
  {
    name: 'BoB (nx)',
    tooltip:
      'Number of rooms already sold  at the same date of the reference year at equivalent leadtime',
  },
  {
    name: 'BoB (CY v nx)',
    tooltip: 'Ratio between "BoB (CY)" and BoB (nx)',
  },
  {
    name: 'Final room sold nx',
    tooltip:
      'Number of rooms sold at the equivalent date of the reference year',
  },
  {
    name: 'Last 10 weeks average (CY)',
    tooltip:
      'Average number of rooms booked over the last ten same weekdays. Current year.',
  },
  {
    name: 'Last 10 weeks average (nx)',
    tooltip:
      'Average number of rooms booked over the last ten same weekdays. Reference year.',
  },
  {
    name: 'Last 10 weeks ratio',
    tooltip:
      'Ratio between "Last 10 weeks average (CY)" and "Last 10 weeks average (nx)"',
  },
  {
    name: 'Initial forecast',
    tooltip: 'Predicted value by the algorithm given past values',
  },
  {
    name: 'Last saved forecast',
    tooltip:
      'Last forecast which has been saved by user. Default value = "Initial forecast"',
  },
  {
    name: 'Change',
    tooltip:
      'Modifier applied to "Last saved forecast" values which are not overridden ',
  },
  {
    name: 'New forecast',
    tooltip:
      'Forecasted room sold number computed with "Change" and "Last Saved forecast"',
  },
  {
    name: 'Previous Forecast',
    tooltip: 'The last saved forecast ',
  },
  {
    name: 'Budget',
    tooltip: 'Rooms sold in the budget',
  },
  {
    name: 'New Forecast vs Budget',
    tooltip: 'Ratio between "New forecast" and "Budget"',
  },
];
