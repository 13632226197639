import { api } from '.';
import { FiltersApiRequest } from '../slices/filtersSlice';

export const resetForecastApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetForecast: builder.mutation<null, FiltersApiRequest>({
      query: (filters) => ({
        url: '/forecasts/reset',
        method: 'PUT',
        body: filters,
      }),
    }),
  }),
});
