import React, { FC, ReactNode } from 'react';
import { LoadingButton as MUIButton } from '@mui/lab';

interface ButtonProps {
  label: string;
  variant?: 'text' | 'contained' | 'outlined';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  size?: 'small' | 'medium' | 'large';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({
  label,
  variant,
  color,
  size,
  startIcon,
  endIcon,
  disabled,
  isLoading,
  fullWidth = true,
  onClick,
}) => {
  return (
    <MUIButton
      variant={variant}
      color={color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      loading={isLoading}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {label}
    </MUIButton>
  );
};

export default Button;
