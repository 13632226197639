import { api } from '.';
import { HotelForecastSum } from '../../utils/constants/forecastSum';
import { UpdateHotelForecast } from '../../utils/constants/updateForecast';
import { FiltersApiRequest } from '../slices/filtersSlice';

interface HotelForecastSumRequest {
  segmentId: number;
  filters: FiltersApiRequest;
}

interface UpdateHotelsForecast {
  filters: FiltersApiRequest;
  resorts: UpdateHotelForecast[];
}

export const hotelForecastApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHotelsForecastSum: builder.query<
      HotelForecastSum[],
      HotelForecastSumRequest
    >({
      query: (hotelForecastSumRequest) => ({
        url: `segments/${hotelForecastSumRequest.segmentId}/resorts/forecasts/sums`,
        method: 'POST',
        body: hotelForecastSumRequest.filters,
      }),
      keepUnusedDataFor: 0,
    }),
    updateHotelsForecast: builder.mutation<null, UpdateHotelsForecast>({
      query: (updateHotelsForecast) => ({
        url: `resorts/forecasts`,
        method: 'PUT',
        body: {
          filters: updateHotelsForecast.filters,
          resorts: updateHotelsForecast.resorts,
        },
      }),
      invalidatesTags: ['TotalForecastSum', 'SegmentForecastSum'],
    }),
  }),
});
