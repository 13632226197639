import { api } from '.';
import { TotalForecastSum } from '../../utils/constants/forecastSum';
import { FiltersApiRequest } from '../slices/filtersSlice';

export const totalForecastApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTotalForecastSum: builder.query<TotalForecastSum, FiltersApiRequest>({
      query: (filters) => ({
        url: '/forecasts/sums',
        method: 'POST',
        body: filters,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['TotalForecastSum'],
    }),
  }),
});
