import { api } from '.';
import { BusinessUnitOption, Option } from '../../utils/constants/option';

interface ResortResponse {
  id: number;
  code: string;
  name: string;
}

export const filtersOptionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getArmZoneOptions: builder.query<Option[], null>({
      query: () => '/arm-zones',
    }),
    getBrandOptions: builder.query<Option[], null>({
      query: () => '/brands',
    }),
    getBusinessUnitOptions: builder.query<BusinessUnitOption[], null>({
      query: () => '/business-units',
      providesTags: ['BusinessUnitOptions'],
    }),
    getCountryOptions: builder.query<Option[], null>({
      query: () => '/countries',
    }),
    getDrmZoneOptions: builder.query<Option[], null>({
      query: () => '/drm-zones',
    }),
    getLegalStatusOptions: builder.query<Option[], null>({
      query: () => '/legal-statuses',
    }),
    getResortOptions: builder.query<Option[], null>({
      query: () => '/resorts',
      transformResponse: (data: ResortResponse[]): Option[] =>
        data.map((resort) => ({
          id: resort.id,
          name: `${resort.code} - ${resort.name}`,
        })),
    }),
  }),
});
