import React, { FC } from 'react';
import {
  Collapse,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import RoomNightsRow from '../rows/RoomNightsRow';
import AdrRow from '../rows/AdrRow';
import RoomRevenueRow from '../rows/RoomRevenueRow';
import NonRoomRevenueRow from '../rows/NonRoomRevenueRow';
import { HotelForecastSum } from '../../../utils/constants/forecastSum';
import DayTables from '../dayTables/DayTables';
import CollapsibleCell from '../cells/CollapsibleCell';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectNewHotelForecast,
  updateNewHotelForecast,
} from '../../../store/slices/newForecastSlice';
import { selectResortCount } from '../../../store/slices/resortCountSlice';
import { selectNumberOfSelectedDays } from '../../../store/slices/filtersSlice';

interface HotelTableProps {
  hotelForcastSum: HotelForecastSum;
}

const HotelTable: FC<HotelTableProps> = ({ hotelForcastSum }) => {
  const dispatch = useAppDispatch();
  const resortCount = useAppSelector(selectResortCount);
  const numberOfSelectedDays = useAppSelector(selectNumberOfSelectedDays);
  const newHotelForecast = useAppSelector((state) =>
    selectNewHotelForecast(
      state,
      hotelForcastSum.segment.id,
      hotelForcastSum.resort.id
    )
  );

  const onChange = (
    newValue: number | null,
    target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue'
  ) => {
    dispatch(
      updateNewHotelForecast({
        newValue,
        target,
        segmentId: hotelForcastSum.segment.id,
        hotelId: hotelForcastSum.resort.id,
      })
    );
  };

  const [open, setOpen] = React.useState<boolean>(false);
  const onClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Stack
        direction="row"
        className={`sticky-column-container ${
          hotelForcastSum.locked ? 'disabled' : ''
        }`}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          className="paper hotel-paper table collapsible-table sticky-column"
        >
          <Table>
            <TableBody>
              <TableRow>
                <CollapsibleCell
                  label={`${hotelForcastSum.resort.code} - ${hotelForcastSum.resort.name}`}
                  collapsible={true}
                  rowType="hotel"
                  open={open}
                  onClick={onClick}
                  disabled={hotelForcastSum.locked}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className="paper hotel-paper table room-nights-table"
        >
          <Table>
            <TableBody>
              <RoomNightsRow
                roomSold={hotelForcastSum.roomSoldSum}
                rowType="hotel"
                newForecast={newHotelForecast}
                onChange={onChange}
                disabled={hotelForcastSum.locked}
              />
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className="paper hotel-paper table adr-table"
        >
          <Table>
            <TableBody>
              <AdrRow
                adr={hotelForcastSum.adrSum}
                rowType="hotel"
                newForecast={newHotelForecast}
                onChange={onChange}
                disabled={hotelForcastSum.locked}
              />
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className="paper hotel-paper table room-revenue-table"
        >
          <Table>
            <TableBody>
              <RoomRevenueRow
                roomRevenue={hotelForcastSum.roomRevenueSum}
                rowType="hotel"
                newForecast={newHotelForecast}
              />
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className="paper hotel-paper table non-room-revenue-table"
        >
          <Table>
            <TableBody>
              <NonRoomRevenueRow
                nonRoomRevenue={hotelForcastSum.nonRoomRevenueSum}
                rowType="hotel"
                newForecast={newHotelForecast}
                onChange={onChange}
                disabled={hotelForcastSum.locked}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Collapse
        in={open}
        timeout={0}
        mountOnEnter={
          !(resortCount !== null && resortCount <= 5 && numberOfSelectedDays <= 31)
        }
        style={{ minHeight: 'auto' }}
      >
        <DayTables
          segmentId={hotelForcastSum.segment.id}
          hotelId={hotelForcastSum.resort.id}
        />
      </Collapse>
    </>
  );
};

export default HotelTable;
