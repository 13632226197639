import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface Credentials {
  token: string | null;
}

const initialState: Credentials = {
  token: localStorage.getItem('token'),
};

function storeCredentials(credentials: Credentials) {
  if (credentials.token) {
    localStorage.setItem('token', credentials.token);
  } else {
    localStorage.removeItem('token');
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      storeCredentials(state);
    },
    logout: (state) => {
      state.token = null;
      storeCredentials(state);
    },
  },
});

export const { login, logout } = authSlice.actions;

export const selectUserIsConnected = (state: RootState): boolean => {
  return state.auth.token !== null;
};

export const selectUserFullName = (state: RootState): string => {
  const decodedValue = window.atob(state.auth.token?.split('.')[1] || '');
  if (decodedValue) {
    return JSON.parse(decodedValue).email;
  } else {
    return '';
  }
};

export const selectUserIsArm = (state: RootState): boolean => {
  const decodedValue = window.atob(state.auth.token?.split('.')[1] || '');
  if (decodedValue) {
    return JSON.parse(decodedValue).user_right === 'Arm';
  } else {
    return false;
  }
};

export const selectUserIsDrm = (state: RootState): boolean => {
  const decodedValue = window.atob(state.auth.token?.split('.')[1] || '');
  if (decodedValue) {
    return JSON.parse(decodedValue).user_right === 'Drm';
  } else {
    return false;
  }
};

export const selectUserIsHeadOf = (state: RootState): boolean => {
  const decodedValue = window.atob(state.auth.token?.split('.')[1] || '');
  if (decodedValue) {
    return JSON.parse(decodedValue).user_right === 'HeadOf';
  } else {
    return false;
  }
};
