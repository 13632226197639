import { ColumnHeader } from './columnHeader';

export const roomRevenueColumnHeaders: ColumnHeader[] = [
  {
    name: 'BoB (CY)',
    tooltip: 'Room revenue already in the system for this date',
  },
  {
    name: 'BoB (nx)',
    tooltip:
      'Room revenue already at the system at the same date of the reference year at equivalent leadtime',
  },
  {
    name: 'Final room revenue nx',
    tooltip:
      'Final room revenue at the equivalent date of the reference year',
  },
  {
    name: 'Initial forecast',
    tooltip:
      'Predicted value by the algorithm given past values. Predicted revenue = predicted ADR x predicted room night',
  },
  {
    name: 'Last saved forecast',
    tooltip:
      'Last forecast which has been saved by user. Default value = "Initial forecast"',
  },
  {
    name: 'New forecast',
    tooltip:
      'Forecasted room revenue computed with forecasted room sold and forecasted adr',
  },
  {
    name: 'Previous Forecast',
    tooltip: 'Last run forecasted room revenue (ht), ',
  },
  {
    name: 'Budget',
    tooltip: 'Room revenue budget.',
  },
  {
    name: 'New Forecast vs Budget',
    tooltip: 'Ratio between "New forecast" and "Budget"',
  },
];
