import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { dayForecastApi } from '../../../store/api/dayForecastApi';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedFiltersApiRequest } from '../../../store/slices/filtersSlice';
import { selectFirstSearch } from '../../../store/slices/searchButtonSlice';
import LoadingRow from '../loadingRow/LoadingRow';
import DayTable from './DayTable';

interface DayTablesProps {
  segmentId: number;
  hotelId: number;
}

const DayTables: FC<DayTablesProps> = ({ segmentId, hotelId }) => {
  const selectedFiltersApiRequest = useAppSelector(
    selectSelectedFiltersApiRequest
  );
  const firstSearch = useAppSelector(selectFirstSearch);

  const {
    data: daysForecast,
    isError,
    isFetching,
  } = dayForecastApi.useGetDaysForecastQuery(
    {
      segmentId,
      hotelId,
      filters: selectedFiltersApiRequest,
    },
    { skip: firstSearch }
  );

  React.useEffect(() => {
    if (isError) {
      toast.error('An error occurred while retrieving days');
    }
  }, [isError]);

  return (
    <>
      {isFetching ? (
        <LoadingRow rowType="day" />
      ) : (
        daysForecast &&
        daysForecast.map((dayForecast) => (
          <DayTable key={dayForecast.forecast.id} dayForecast={dayForecast} />
        ))
      )}
    </>
  );
};

export default DayTables;
