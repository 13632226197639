import React, { FC } from 'react';
import {
  Paper,
  Skeleton,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { roomNightsColumnHeaders } from '../../../utils/constants/columnHeaders/roomNights';
import { adrColumnHeaders } from '../../../utils/constants/columnHeaders/adr';
import { roomRevenueColumnHeaders } from '../../../utils/constants/columnHeaders/roomRevenue';
import { nonRoomRevenueColumnHeaders } from '../../../utils/constants/columnHeaders/nonRoomRevenue';
import LoadingTable from './LoadingTable';

interface LoadingCellProps {
  rowType: 'total' | 'segment' | 'hotel' | 'day';
}

const LoadingRow: FC<LoadingCellProps> = ({ rowType }) => {
  return (
    <Stack direction="row" className="sticky-column-container">
      <TableContainer
        component={Paper}
        elevation={0}
        className={`paper table collapsible-table sticky-column 
          ${rowType === 'hotel' ? 'hotel-paper' : ''} 
          ${rowType === 'day' ? 'day-paper' : ''}
        `}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="collapsible-loading-cell">
                <Skeleton animation="wave" />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <LoadingTable
        columns={roomNightsColumnHeaders}
        rowType={rowType}
        tableType="room-nights-table"
      />
      <LoadingTable
        columns={adrColumnHeaders}
        rowType={rowType}
        tableType="adr-table"
      />
      <LoadingTable
        columns={roomRevenueColumnHeaders}
        rowType={rowType}
        tableType="room-revenue-table"
      />
      <LoadingTable
        columns={nonRoomRevenueColumnHeaders}
        rowType={rowType}
        tableType="non-room-revenue-table"
      />
    </Stack>
  );
};

export default LoadingRow;
