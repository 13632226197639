import React, { FC } from 'react';
import { TableCell } from '@mui/material';
import { roundWithPrecision } from '../../../utils/functions/round';

interface NumericCellProps {
  value: number;
  percentage?: boolean;
  precision?: number;
  potentialRoom?: number;
  onClick?: (event: React.MouseEvent<HTMLTableCellElement>) => void;
}

const NumericCell: FC<NumericCellProps> = ({
  value,
  percentage = false,
  precision,
  potentialRoom,
  onClick,
}) => {
  const precisionValue =
    precision !== undefined
      ? roundWithPrecision(value, precision).toLocaleString()
      : value.toLocaleString();

  const textValue =
    isNaN(value) || value === Infinity || value === -Infinity
      ? '0'
      : precisionValue;

  const textPercentage = percentage ? ' %' : '';

  const occupancyRate = potentialRoom
    ? Math.floor(((value + 0.49) / potentialRoom) * 100)
    : null;

  const textOccupancyRate = potentialRoom
    ? ` (${Math.floor(((value + 0.49) / potentialRoom) * 100)}%)`
    : '';

  return (
    <TableCell
      align="center"
      className={`numeric-cell ${onClick ? "clickable" : ""}`}
      onClick={(event) => (onClick ? onClick(event) : null)}
    >
      {textValue}
      {textPercentage}
      <span
        className={
          occupancyRate && occupancyRate > 100
            ? 'exceeded-occupancy-rate'
            : 'occupancy-rate'
        }
      >
        {textOccupancyRate}
      </span>
    </TableCell>
  );
};

export default NumericCell;
