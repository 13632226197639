import React, { FC } from 'react';
import { TableRow } from '@mui/material';
import { RoomRevenue } from '../../../utils/constants/forecastSum';
import NumericCell from '../cells/NumericCell';
import { NewForecast } from '../../../store/slices/newForecastSlice';

interface RoomRevenueRowProps {
  roomRevenue: RoomRevenue;
  rowType: 'total' | 'segment' | 'hotel' | 'day';
  newForecast: NewForecast;
}

const RoomRevenueRow: FC<RoomRevenueRowProps> = ({
  roomRevenue,
  rowType,
  newForecast,
}) => {
  return (
    <TableRow>
      <NumericCell value={roomRevenue.bob} />
      <NumericCell value={roomRevenue.bobLag} />
      <NumericCell value={roomRevenue.lag} />
      <NumericCell value={roomRevenue.initialForecast} />
      <NumericCell value={roomRevenue.newForecast} />
      <NumericCell
        value={newForecast.roomSoldForecast * newForecast.adrForecast}
        precision={3}
      />
      <NumericCell value={roomRevenue.previousForecast} />
      <NumericCell value={roomRevenue.budget} precision={0} />
      <NumericCell
        value={
          ((newForecast.roomSoldForecast * newForecast.adrForecast) /
            roomRevenue.budget) *
          100
        }
        percentage
        precision={0}
      />
    </TableRow>
  );
};

export default RoomRevenueRow;
