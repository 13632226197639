import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { dayForecastApi } from '../api/dayForecastApi';
import { hotelForecastApi } from '../api/hotelForecastApi';
import { segmentForecastApi } from '../api/segmentForecastApi';
import { totalForecastApi } from '../api/totalForecastApi';

interface SearchButton {
  firstSearch: boolean;
  isSearching: boolean;
}

const initialState: SearchButton = {
  firstSearch: true,
  isSearching: false,
};

export const searchButtonSlice = createSlice({
  name: 'searchButton',
  initialState,
  reducers: {
    updateFirstSearch: (state, action: PayloadAction<boolean>) => {
      state.firstSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          totalForecastApi.endpoints.getTotalForecastSum.matchFulfilled,
          segmentForecastApi.endpoints.getSegmentsForecastSum.matchFulfilled,
          hotelForecastApi.endpoints.getHotelsForecastSum.matchFulfilled,
          dayForecastApi.endpoints.getDaysForecast.matchFulfilled
        ),
        (state) => {
          state.isSearching = false;
        }
      )
      .addMatcher(
        isAnyOf(
          totalForecastApi.endpoints.getTotalForecastSum.matchPending,
          segmentForecastApi.endpoints.getSegmentsForecastSum.matchPending,
          hotelForecastApi.endpoints.getHotelsForecastSum.matchPending,
          dayForecastApi.endpoints.getDaysForecast.matchPending
        ),
        (state) => {
          state.isSearching = true;
        }
      )
      .addMatcher(
        isAnyOf(
          totalForecastApi.endpoints.getTotalForecastSum.matchRejected,
          segmentForecastApi.endpoints.getSegmentsForecastSum.matchRejected,
          hotelForecastApi.endpoints.getHotelsForecastSum.matchRejected,
          dayForecastApi.endpoints.getDaysForecast.matchRejected
        ),
        (state) => {
          state.isSearching = false;
        }
      );
  },
});

export const { updateFirstSearch } = searchButtonSlice.actions;

export const selectFirstSearch = (state: RootState): boolean => {
  return state.searchButton.firstSearch;
};

export const selectIsSearching = (state: RootState): boolean => {
  return state.searchButton.isSearching;
};
