import { Option } from '../option';

export const comparableOptions: Option[] = [
  {
    id: 1,
    name: 'Yes',
  },
  {
    id: 2,
    name: 'No',
  },
];
