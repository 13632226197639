import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  DayForecast,
  HotelForecastSum,
  SegmentForecastSum,
  TotalForecastSum,
} from '../../utils/constants/forecastSum';
import {
  UpdateDayForecast,
  UpdateHotelForecast,
  UpdateSegmentForecast,
} from '../../utils/constants/updateForecast';
import { roundWithPrecision } from '../../utils/functions/round';
import { dayForecastApi } from '../api/dayForecastApi';
import { hotelForecastApi } from '../api/hotelForecastApi';
import { segmentForecastApi } from '../api/segmentForecastApi';
import { totalForecastApi } from '../api/totalForecastApi';

export interface NewForecast {
  adrForecast: number;
  roomSoldForecast: number;
  nonRoomRevenueForecast: number;
  adrFixedDaySum: number;
  roomSoldFixedDaySum: number;
  nonRoomRevenueFixedDaySum: number;
}

interface Lock {
  locked: boolean;
}

interface CalculatedForecast {
  adrValue: number | null;
  roomSoldValue: number | null;
  nonRoomRevenueValue: number | null;
  adrForecast: number;
  roomSoldForecast: number;
  nonRoomRevenueForecast: number;
  adrForecastLock: number;
  roomSoldForecastLock: number;
  nonRoomRevenueForecastLock: number;
}

interface NewTotalForecast extends Lock, CalculatedForecast {
  segments: NewSegmentForecast[];
}

interface NewSegmentForecast extends Lock, CalculatedForecast {
  id: number;
  resorts: NewHotelForecast[];
  childUpdated: boolean;
}

interface NewHotelForecast extends Lock, CalculatedForecast {
  id: number;
  forecasts: NewDayForecast[];
  childUpdated: boolean;
}

interface NewDayForecast extends Lock, CalculatedForecast {
  id: number;
}

interface ForecastForUpdate {
  segments: UpdateSegmentForecast[];
  resorts: UpdateHotelForecast[];
  forecasts: UpdateDayForecast[];
}

const initialState: NewTotalForecast = {
  adrValue: null,
  roomSoldValue: null,
  nonRoomRevenueValue: null,
  adrForecast: 0,
  roomSoldForecast: 0,
  nonRoomRevenueForecast: 0,
  adrForecastLock: 0,
  roomSoldForecastLock: 0,
  nonRoomRevenueForecastLock: 0,
  segments: [],
  locked: false,
};

export const newForecastSlice = createSlice({
  name: 'newForecast',
  initialState,
  reducers: {
    resetNewForecast: () => initialState,
    updateNewDayForecast: (
      state,
      action: PayloadAction<{
        newValue: number | null;
        target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue';
        segmentId: number;
        hotelId: number;
        dayId: number;
      }>
    ) => {
      state.segments
        .find((segment) => segment.id === action.payload.segmentId)!
        .resorts.find((hotel) => hotel.id === action.payload.hotelId)!
        .forecasts.find((day) => day.id === action.payload.dayId)![
        action.payload.target
      ] = action.payload.newValue;
      state.segments
        .find((segment) => segment.id === action.payload.segmentId)!
        .resorts.find(
          (hotel) => hotel.id === action.payload.hotelId
        )!.childUpdated = true;
      state.segments.find(
        (segment) => segment.id === action.payload.segmentId
      )!.childUpdated = true;
    },
    updateNewHotelForecast: (
      state,
      action: PayloadAction<{
        newValue: number | null;
        target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue';
        segmentId: number;
        hotelId: number;
      }>
    ) => {
      state.segments
        .find((segment) => segment.id === action.payload.segmentId)!
        .resorts.find((hotel) => hotel.id === action.payload.hotelId)![
        action.payload.target
      ] = action.payload.newValue;
      state.segments.find(
        (segment) => segment.id === action.payload.segmentId
      )!.childUpdated = true;
    },
    updateNewSegmentForecast: (
      state,
      action: PayloadAction<{
        newValue: number | null;
        target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue';
        segmentId: number;
      }>
    ) => {
      state.segments.find(
        (segment) => segment.id === action.payload.segmentId
      )![action.payload.target] = action.payload.newValue;
    },
    updateNewTotalForecast: (
      state,
      action: PayloadAction<{
        newValue: number | null;
        target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue';
      }>
    ) => {
      state[action.payload.target] = action.payload.newValue;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      totalForecastApi.endpoints.getTotalForecastSum.matchFulfilled,
      (state, action: PayloadAction<TotalForecastSum>) => {
        if (action.payload) {
          state.adrValue = null;
          state.roomSoldValue = null;
          state.nonRoomRevenueValue = null;
          state.adrForecast = action.payload.adrSum.newForecast;
          state.roomSoldForecast = action.payload.roomSoldSum.newForecast;
          state.nonRoomRevenueForecast =
            action.payload.nonRoomRevenueSum.newForecast;
          state.adrForecastLock = action.payload.adrSum.newForecastLocked;
          state.roomSoldForecastLock =
            action.payload.roomSoldSum.newForecastLocked;
          state.nonRoomRevenueForecastLock =
            action.payload.nonRoomRevenueSum.newForecastLocked;
          state.locked = action.payload.locked;
        }
      }
    );
    builder.addMatcher(
      segmentForecastApi.endpoints.getSegmentsForecastSum.matchFulfilled,
      (state, action: PayloadAction<SegmentForecastSum[]>) => {
        state.segments = [];
        if (action.payload) {
          action.payload.forEach((segmentForecastSum) => {
            state.segments.push({
              id: segmentForecastSum.segment.id,
              adrValue: null,
              roomSoldValue: null,
              nonRoomRevenueValue: null,
              adrForecast: segmentForecastSum.adrSum.newForecast,
              roomSoldForecast: segmentForecastSum.roomSoldSum.newForecast,
              nonRoomRevenueForecast:
                segmentForecastSum.nonRoomRevenueSum.newForecast,
              adrForecastLock: segmentForecastSum.adrSum.newForecastLocked,
              roomSoldForecastLock:
                segmentForecastSum.roomSoldSum.newForecastLocked,
              nonRoomRevenueForecastLock:
                segmentForecastSum.nonRoomRevenueSum.newForecastLocked,
              resorts: [],
              childUpdated: false,
              locked: segmentForecastSum.locked,
            });
          });
        }
      }
    );
    builder.addMatcher(
      hotelForecastApi.endpoints.getHotelsForecastSum.matchFulfilled,
      (state, action: PayloadAction<HotelForecastSum[]>) => {
        action.payload.forEach((hotelForecastSum) => {
          state.segments
            .find((segment) => segment.id === hotelForecastSum.segment.id)!
            .resorts.push({
              id: hotelForecastSum.resort.id,
              adrValue: null,
              roomSoldValue: null,
              nonRoomRevenueValue: null,
              adrForecast: hotelForecastSum.adrSum.newForecast,
              roomSoldForecast: hotelForecastSum.roomSoldSum.newForecast,
              nonRoomRevenueForecast:
                hotelForecastSum.nonRoomRevenueSum.newForecast,
              adrForecastLock: hotelForecastSum.adrSum.newForecastLocked,
              roomSoldForecastLock:
                hotelForecastSum.roomSoldSum.newForecastLocked,
              nonRoomRevenueForecastLock:
                hotelForecastSum.nonRoomRevenueSum.newForecastLocked,
              forecasts: [],
              childUpdated: false,
              locked: hotelForecastSum.locked,
            });
        });
      }
    );
    builder.addMatcher(
      dayForecastApi.endpoints.getDaysForecast.matchFulfilled,
      (state, action: PayloadAction<DayForecast[]>) => {
        action.payload.forEach((dayForecast) => {
          state.segments
            .find((segment) => segment.id === dayForecast.segment.id)!
            .resorts.find((hotel) => hotel.id === dayForecast.resort.id)!
            .forecasts.push({
              id: dayForecast.forecast.id,
              adrValue: null,
              roomSoldValue: null,
              nonRoomRevenueValue: null,
              adrForecast: dayForecast.adrSum.newForecast,
              roomSoldForecast: dayForecast.roomSoldSum.newForecast,
              nonRoomRevenueForecast: dayForecast.nonRoomRevenueSum.newForecast,
              adrForecastLock: dayForecast.adrSum.newForecastLocked,
              roomSoldForecastLock: dayForecast.roomSoldSum.newForecastLocked,
              nonRoomRevenueForecastLock:
                dayForecast.nonRoomRevenueSum.newForecastLocked,
              locked: dayForecast.locked,
            });
        });
      }
    );
  },
});

export const {
  resetNewForecast,
  updateNewDayForecast,
  updateNewHotelForecast,
  updateNewSegmentForecast,
  updateNewTotalForecast,
} = newForecastSlice.actions;

export const selectNewDayForecast = (
  state: RootState,
  segmentId: number,
  hotelId: number,
  dayId: number
): NewForecast => {
  const totalForecast = state.newForecast;
  const segmentForecast = totalForecast.segments.find(
    (segment) => segment.id === segmentId
  )!;
  const hotelForecast = segmentForecast.resorts.find(
    (hotel) => hotel.id === hotelId
  )!;
  const dayForecast = hotelForecast.forecasts.find((day) => day.id === dayId)!;

  return {
    adrForecast: dayForecast.locked
      ? dayForecast.adrForecastLock
      : dayForecast.adrValue !== null
      ? dayForecast.adrValue
      : roundWithPrecision(
          dayForecast.adrForecast *
            (1 +
              toPercentage(totalForecast.adrValue) +
              toPercentage(segmentForecast.adrValue) +
              toPercentage(hotelForecast.adrValue)),
          3
        ),
    roomSoldForecast: dayForecast.locked
      ? dayForecast.roomSoldForecastLock
      : dayForecast.roomSoldValue !== null
      ? dayForecast.roomSoldValue
      : roundWithPrecision(
          dayForecast.roomSoldForecast *
            (1 +
              toPercentage(totalForecast.roomSoldValue) +
              toPercentage(segmentForecast.roomSoldValue) +
              toPercentage(hotelForecast.roomSoldValue)),
          3
        ),
    nonRoomRevenueForecast: dayForecast.locked
      ? dayForecast.nonRoomRevenueForecastLock
      : dayForecast.nonRoomRevenueValue !== null
      ? dayForecast.nonRoomRevenueValue
      : roundWithPrecision(
          dayForecast.nonRoomRevenueForecast *
            (1 +
              toPercentage(totalForecast.nonRoomRevenueValue) +
              toPercentage(segmentForecast.nonRoomRevenueValue) +
              toPercentage(hotelForecast.nonRoomRevenueValue)),
          3
        ),
    adrFixedDaySum: dayForecast.adrValue !== null ? dayForecast.adrForecast : 0,
    roomSoldFixedDaySum:
      dayForecast.roomSoldValue !== null ? dayForecast.roomSoldForecast : 0,
    nonRoomRevenueFixedDaySum:
      dayForecast.nonRoomRevenueValue !== null
        ? dayForecast.nonRoomRevenueForecast
        : 0,
  };
};

export const selectNewHotelForecast = (
  state: RootState,
  segmentId: number,
  hotelId: number
): NewForecast => {
  const totalForecast = state.newForecast;
  const segmentForecast = totalForecast.segments.find(
    (segment) => segment.id === segmentId
  )!;
  const hotelForecast = segmentForecast.resorts.find(
    (hotel) => hotel.id === hotelId
  )!;

  if (hotelForecast.forecasts.length) {
    const daysForecast = hotelForecast.forecasts.map((day) =>
      selectNewDayForecast(state, segmentId, hotelId, day.id)
    );

    const roomSoldForecast = daysForecast
      .map((dayForecast) => dayForecast.roomSoldForecast)
      .reduce((a, b) => a + b, 0);

    const adrForecast =
      roomSoldForecast === 0
        ? 0
        : daysForecast
            .map(
              (dayForecast) =>
                dayForecast.adrForecast * dayForecast.roomSoldForecast
            )
            .reduce((a, b) => a + b, 0) / roomSoldForecast;

    const adrFixedDaySum =
      roomSoldForecast === 0
        ? 0
        : daysForecast
            .map(
              (dayForecast) =>
                dayForecast.adrFixedDaySum * dayForecast.roomSoldForecast
            )
            .reduce((a, b) => a + b, 0) / roomSoldForecast;

    return {
      adrForecast: adrForecast,
      roomSoldForecast: roomSoldForecast,
      nonRoomRevenueForecast: daysForecast
        .map((dayForecast) => dayForecast.nonRoomRevenueForecast)
        .reduce((a, b) => a + b, 0),
      adrFixedDaySum: adrFixedDaySum,
      roomSoldFixedDaySum: daysForecast
        .map((dayForecast) => dayForecast.roomSoldFixedDaySum)
        .reduce((a, b) => a + b, 0),
      nonRoomRevenueFixedDaySum: daysForecast
        .map((dayForecast) => dayForecast.nonRoomRevenueFixedDaySum)
        .reduce((a, b) => a + b, 0),
    };
  } else {
    return {
      adrForecast: roundWithPrecision(
        hotelForecast.adrForecastLock +
          hotelForecast.adrForecast *
            (1 +
              toPercentage(totalForecast.adrValue) +
              toPercentage(segmentForecast.adrValue) +
              toPercentage(hotelForecast.adrValue)),
        3
      ),
      roomSoldForecast: roundWithPrecision(
        hotelForecast.roomSoldForecastLock +
          hotelForecast.roomSoldForecast *
            (1 +
              toPercentage(totalForecast.roomSoldValue) +
              toPercentage(segmentForecast.roomSoldValue) +
              toPercentage(hotelForecast.roomSoldValue)),
        3
      ),
      nonRoomRevenueForecast: roundWithPrecision(
        hotelForecast.nonRoomRevenueForecastLock +
          hotelForecast.nonRoomRevenueForecast *
            (1 +
              toPercentage(totalForecast.nonRoomRevenueValue) +
              toPercentage(segmentForecast.nonRoomRevenueValue) +
              toPercentage(hotelForecast.nonRoomRevenueValue)),
        3
      ),
      adrFixedDaySum: 0,
      roomSoldFixedDaySum: 0,
      nonRoomRevenueFixedDaySum: 0,
    };
  }
};

export const selectNewSegmentForecast = (
  state: RootState,
  segmentId: number
): NewForecast => {
  const totalForecast = state.newForecast;
  const segmentForecast = totalForecast.segments.find(
    (segment) => segment.id === segmentId
  )!;

  if (segmentForecast.resorts.length) {
    const hotelsForecast = segmentForecast.resorts.map((hotel) =>
      selectNewHotelForecast(state, segmentId, hotel.id)
    );

    const roomSoldForecast = hotelsForecast
      .map((hotelForecast) => hotelForecast.roomSoldForecast)
      .reduce((a, b) => a + b, 0);

    const adrForecast =
      roomSoldForecast === 0
        ? 0
        : hotelsForecast
            .map(
              (hotelForecast) =>
                hotelForecast.adrForecast * hotelForecast.roomSoldForecast
            )
            .reduce((a, b) => a + b, 0) / roomSoldForecast;

    const adrFixedDaySum =
      roomSoldForecast === 0
        ? 0
        : hotelsForecast
            .map(
              (hotelForecast) =>
                hotelForecast.adrFixedDaySum * hotelForecast.roomSoldForecast
            )
            .reduce((a, b) => a + b, 0) / roomSoldForecast;

    return {
      adrForecast: adrForecast,
      roomSoldForecast: roomSoldForecast,
      nonRoomRevenueForecast: hotelsForecast
        .map((hotelForecast) => hotelForecast.nonRoomRevenueForecast)
        .reduce((a, b) => a + b, 0),
      adrFixedDaySum: adrFixedDaySum,
      roomSoldFixedDaySum: hotelsForecast
        .map((hotelForecast) => hotelForecast.roomSoldFixedDaySum)
        .reduce((a, b) => a + b, 0),
      nonRoomRevenueFixedDaySum: hotelsForecast
        .map((hotelForecast) => hotelForecast.nonRoomRevenueFixedDaySum)
        .reduce((a, b) => a + b, 0),
    };
  } else {
    return {
      adrForecast: roundWithPrecision(
        segmentForecast.adrForecastLock +
          segmentForecast.adrForecast *
            (1 +
              toPercentage(totalForecast.adrValue) +
              toPercentage(segmentForecast.adrValue)),
        3
      ),
      roomSoldForecast: roundWithPrecision(
        segmentForecast.roomSoldForecastLock +
          segmentForecast.roomSoldForecast *
            (1 +
              toPercentage(totalForecast.roomSoldValue) +
              toPercentage(segmentForecast.roomSoldValue)),
        3
      ),
      nonRoomRevenueForecast: roundWithPrecision(
        segmentForecast.nonRoomRevenueForecastLock +
          segmentForecast.nonRoomRevenueForecast *
            (1 +
              toPercentage(totalForecast.nonRoomRevenueValue) +
              toPercentage(segmentForecast.nonRoomRevenueValue)),
        3
      ),
      adrFixedDaySum: 0,
      roomSoldFixedDaySum: 0,
      nonRoomRevenueFixedDaySum: 0,
    };
  }
};

export const selectNewTotalForecast = (state: RootState): NewForecast => {
  const totalForecast = state.newForecast;

  if (totalForecast.segments.length) {
    const segmentsForecast = totalForecast.segments.map((segment) =>
      selectNewSegmentForecast(state, segment.id)
    );

    const roomSoldForecast = segmentsForecast
      .map((segmentForecast) => segmentForecast.roomSoldForecast)
      .reduce((a, b) => a + b, 0);

    const adrForecast =
      roomSoldForecast === 0
        ? 0
        : segmentsForecast
            .map(
              (segmentForecast) =>
                segmentForecast.adrForecast * segmentForecast.roomSoldForecast
            )
            .reduce((a, b) => a + b, 0) / roomSoldForecast;

    const adrFixedDaySum =
      roomSoldForecast === 0
        ? 0
        : segmentsForecast
            .map(
              (segmentForecast) =>
                segmentForecast.adrFixedDaySum *
                segmentForecast.roomSoldForecast
            )
            .reduce((a, b) => a + b, 0) / roomSoldForecast;

    return {
      adrForecast: adrForecast,
      roomSoldForecast: roomSoldForecast,
      nonRoomRevenueForecast: segmentsForecast
        .map((segmentForecast) => segmentForecast.nonRoomRevenueForecast)
        .reduce((a, b) => a + b, 0),
      adrFixedDaySum: adrFixedDaySum,
      roomSoldFixedDaySum: segmentsForecast
        .map((segmentForecast) => segmentForecast.roomSoldFixedDaySum)
        .reduce((a, b) => a + b, 0),
      nonRoomRevenueFixedDaySum: segmentsForecast
        .map((segmentForecast) => segmentForecast.nonRoomRevenueFixedDaySum)
        .reduce((a, b) => a + b, 0),
    };
  } else {
    return {
      adrForecast:
        totalForecast.adrForecastLock +
        totalForecast.adrForecast * (1 + toPercentage(totalForecast.adrValue)),
      roomSoldForecast:
        totalForecast.roomSoldForecastLock +
        totalForecast.roomSoldForecast *
          (1 + toPercentage(totalForecast.roomSoldValue)),
      nonRoomRevenueForecast:
        totalForecast.nonRoomRevenueForecastLock +
        totalForecast.nonRoomRevenueForecast *
          (1 + toPercentage(totalForecast.nonRoomRevenueValue)),
      adrFixedDaySum: 0,
      roomSoldFixedDaySum: 0,
      nonRoomRevenueFixedDaySum: 0,
    };
  }
};

export const selectNewForecast = (state: RootState): ForecastForUpdate => {
  let segments: UpdateSegmentForecast[] = [];
  let resorts: UpdateHotelForecast[] = [];
  let forecasts: UpdateDayForecast[] = [];

  state.newForecast.segments.forEach((segment) => {
    if (!segment.childUpdated) {
      if (
        state.newForecast.adrValue ||
        state.newForecast.roomSoldValue ||
        state.newForecast.nonRoomRevenueValue ||
        segment.adrValue ||
        segment.roomSoldValue ||
        segment.nonRoomRevenueValue
      )
        segments.push({
          segmentId: segment.id,
          adrPercentage:
            state.newForecast.adrValue || segment.adrValue
              ? (state.newForecast.adrValue ?? 0) + (segment.adrValue ?? 0)
              : null,
          roomSoldPercentage:
            state.newForecast.roomSoldValue || segment.roomSoldValue
              ? (state.newForecast.roomSoldValue ?? 0) +
                (segment.roomSoldValue ?? 0)
              : null,
          nonRoomRevenuePercentage:
            state.newForecast.nonRoomRevenueValue || segment.nonRoomRevenueValue
              ? (state.newForecast.nonRoomRevenueValue ?? 0) +
                (segment.nonRoomRevenueValue ?? 0)
              : null,
        });
    } else {
      segment.resorts.forEach((resort) => {
        if (!resort.childUpdated) {
          if (
            state.newForecast.adrValue ||
            state.newForecast.roomSoldValue ||
            state.newForecast.nonRoomRevenueValue ||
            segment.adrValue ||
            segment.roomSoldValue ||
            segment.nonRoomRevenueValue ||
            resort.adrValue ||
            resort.roomSoldValue ||
            resort.nonRoomRevenueValue
          )
            resorts.push({
              segmentId: segment.id,
              resortId: resort.id,
              adrPercentage:
                state.newForecast.adrValue ||
                segment.adrValue ||
                resort.adrValue
                  ? (state.newForecast.adrValue ?? 0) +
                    (segment.adrValue ?? 0) +
                    (resort.adrValue ?? 0)
                  : null,
              roomSoldPercentage:
                state.newForecast.roomSoldValue ||
                segment.roomSoldValue ||
                resort.roomSoldValue
                  ? (state.newForecast.roomSoldValue ?? 0) +
                    (segment.roomSoldValue ?? 0) +
                    (resort.roomSoldValue ?? 0)
                  : null,
              nonRoomRevenuePercentage:
                state.newForecast.nonRoomRevenueValue ||
                segment.nonRoomRevenueValue ||
                resort.nonRoomRevenueValue
                  ? (state.newForecast.nonRoomRevenueValue ?? 0) +
                    (segment.nonRoomRevenueValue ?? 0) +
                    (resort.nonRoomRevenueValue ?? 0)
                  : null,
            });
        } else {
          resort.forecasts.forEach((forecast) => {
            if (
              state.newForecast.adrValue ||
              state.newForecast.roomSoldValue ||
              state.newForecast.nonRoomRevenueValue ||
              segment.adrValue ||
              segment.roomSoldValue ||
              segment.nonRoomRevenueValue ||
              resort.adrValue ||
              resort.roomSoldValue ||
              resort.nonRoomRevenueValue ||
              forecast.adrValue !== null ||
              forecast.roomSoldValue !== null ||
              forecast.nonRoomRevenueValue !== null
            ) {
              forecasts.push({
                id: forecast.id,
                adrForecast:
                  forecast.adrValue !== null
                    ? forecast.adrValue
                    : forecast.adrForecast *
                      (1 +
                        toPercentage(state.newForecast.adrValue) +
                        toPercentage(segment.adrValue) +
                        toPercentage(resort.adrValue)),
                roomSoldForecast:
                  forecast.roomSoldValue !== null
                    ? forecast.roomSoldValue
                    : forecast.roomSoldForecast *
                      (1 +
                        toPercentage(state.newForecast.roomSoldValue) +
                        toPercentage(segment.roomSoldValue) +
                        toPercentage(resort.roomSoldValue)),
                nonRoomRevenueForecast:
                  forecast.nonRoomRevenueValue !== null
                    ? forecast.nonRoomRevenueValue
                    : forecast.nonRoomRevenueForecast *
                      (1 +
                        toPercentage(state.newForecast.nonRoomRevenueValue) +
                        toPercentage(segment.nonRoomRevenueValue) +
                        toPercentage(resort.nonRoomRevenueValue)),
              });
            }
          });
        }
      });
    }
  });

  return { segments, resorts, forecasts };
};

const toPercentage = (value: number | null): number => {
  return value ? value / 100 : 0;
};
