import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  differenceInCalendarDays,
  endOfMonth,
  format,
  startOfMonth,
} from 'date-fns';
import { RootState } from '..';
import { Option } from '../../utils/constants/option';
import { selectConvertToEur, selectConvertToTTC } from './moneySlice';

export interface Filters {
  startDate: string | null;
  endDate: string | null;
  armZones: Option[];
  brands: Option[];
  businessUnits: Option[];
  drmZones: Option[];
  comparableBudget: Option[];
  comparableNX: Option[];
  countries: Option[];
  legalStatuses: Option[];
  resorts: Option[];
}

export interface FiltersApiRequest {
  startDate: string | null;
  endDate: string | null;
  armZones: number[] | null;
  brands: number[] | null;
  businessUnits: number[] | null;
  drmZones: number[] | null;
  comparableBudget: boolean | null;
  comparableNX: boolean | null;
  convertToEur: boolean;
  convertToTTC: boolean;
  countries: number[] | null;
  legalStatuses: number[] | null;
  resorts: number[] | null;
}

const initialState: Filters = {
  startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
  endDate: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
  armZones: [],
  brands: [],
  businessUnits: [],
  drmZones: [],
  comparableBudget: [],
  comparableNX: [],
  countries: [],
  legalStatuses: [],
  resorts: [],
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<Filters>) => action.payload,
  },
});

export const { updateFilters } = filtersSlice.actions;

export const selectSelectedFilters = (state: RootState): Filters => {
  return state.filters;
};

export const selectSelectedFiltersApiRequest = (
  state: RootState
): FiltersApiRequest => {
  return {
    startDate: state.filters.startDate,
    endDate: state.filters.endDate,
    armZones: state.filters.armZones.length
      ? state.filters.armZones.map((armZone) => armZone.id)
      : null,
    brands: state.filters.brands.length
      ? state.filters.brands.map((brand) => brand.id)
      : null,
    businessUnits: state.filters.businessUnits.length
      ? state.filters.businessUnits.map((businessUnit) => businessUnit.id)
      : null,
    drmZones: state.filters.drmZones.length
      ? state.filters.drmZones.map((drmZone) => drmZone.id)
      : null,
    comparableBudget:
      state.filters.comparableBudget.length === 1
        ? state.filters.comparableBudget[0].id === 1
        : null,
    comparableNX:
      state.filters.comparableNX.length === 1
        ? state.filters.comparableNX[0].id === 1
        : null,
    convertToEur: selectConvertToEur(state),
    convertToTTC: selectConvertToTTC(state),
    countries: state.filters.countries.length
      ? state.filters.countries.map((country) => country.id)
      : null,
    legalStatuses: state.filters.legalStatuses.length
      ? state.filters.legalStatuses.map((legalStatus) => legalStatus.id)
      : null,
    resorts: state.filters.resorts.length
      ? state.filters.resorts.map((resort) => resort.id)
      : null,
  };
};

export const selectActiveFilters = (state: RootState): number => {
  let sum = 0;
  Object.entries(state.filters).forEach((entry) => {
    const [key, value] = entry;
    if (key !== 'startDate' && key !== 'endDate' && value.length) {
      sum++;
    }
  });
  return sum + 1;
};

export const selectNumberOfSelectedDays = (state: RootState): number => {
  if (state.filters.startDate && state.filters.endDate) {
    return (
      differenceInCalendarDays(
        new Date(state.filters.endDate),
        new Date(state.filters.startDate)
      ) + 1
    );
  }
  return 0;
};
