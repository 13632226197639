import React, { FC } from 'react';
import FilterSection from '../../components/filters/FilterSection';
import ResultSection from '../../components/results/ResultSection';

const Forecast: FC = () => {
  return (
    <>
      <FilterSection />
      <ResultSection />
    </>
  );
};

export default Forecast;
