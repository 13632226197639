import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface Money {
  convertToEur: boolean;
  convertToTTC: boolean;
}

const initialState: Money = {
  convertToEur: false,
  convertToTTC: false,
};

export const moneySlice = createSlice({
  name: 'money',
  initialState,
  reducers: {
    updateConvertToEur: (state, action: PayloadAction<boolean>) => {
      state.convertToEur = action.payload;
    },
    updateConvertToTTC: (state, action: PayloadAction<boolean>) => {
      state.convertToTTC = action.payload;
    },
  },
});

export const { updateConvertToEur, updateConvertToTTC } = moneySlice.actions;

export const selectConvertToEur = (state: RootState): boolean => {
  return state.money.convertToEur;
};

export const selectConvertToTTC = (state: RootState): boolean => {
  return state.money.convertToTTC;
};
