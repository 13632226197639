import React, { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { InputAdornment, Stack, TableCell, TextField } from '@mui/material';
import Button from '../../common/button/Button';
import { useDebouncedCallback } from 'use-debounce';
import { roundWithPrecision } from '../../../utils/functions/round';

interface ChangeCellProps {
  rowType: 'total' | 'segment' | 'hotel' | 'day';
  target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue';
  onChange: (
    newValue: number | null,
    target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue'
  ) => void;
  disabled?: boolean;
  calculatedValue?: string;
}

const ChangeCell: FC<ChangeCellProps> = ({
  rowType,
  target,
  onChange,
  disabled = false,
  calculatedValue,
}) => {
  const floatRegExp = new RegExp('^[-]?([0-9]+([.][0-9]*)?)$');
  const intRegExp = new RegExp('^([0-9]+)$');
  const debounced = useDebouncedCallback(
    (value: number | null) => onChange(value, target),
    300
  );

  const [stringValue, setStringValue] = React.useState<string>('');
  const handleChangeStringValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    if (rowType === 'day') {
      if (newValue === '' || intRegExp.test(newValue)) {
        setStringValue(newValue);
        !isNaN(parseInt(newValue))
          ? debounced(parseInt(newValue))
          : debounced(null);
      }
    } else {
      if (newValue === '' || newValue === '-' || floatRegExp.test(newValue)) {
        setStringValue(newValue);
        !isNaN(parseFloat(newValue))
          ? debounced(parseFloat(newValue))
          : debounced(null);
      }
    }
  };

  const removeOne = () => {
    if (stringValue === '' || stringValue === '-') {
      setStringValue('-1');
      debounced(parseFloat('-1'));
    } else {
      let newValue: number;
      parseFloat(stringValue) < 0 || Number.isInteger(parseFloat(stringValue))
        ? (newValue = Math.trunc(parseFloat(stringValue)) - 1)
        : (newValue = Math.trunc(parseFloat(stringValue)));

      setStringValue(newValue.toString());
      debounced(newValue);
    }
  };

  const addOne = () => {
    if (stringValue === '' || stringValue === '-') {
      setStringValue('1');
      debounced(parseFloat('1'));
    } else {
      let newValue: number;
      parseFloat(stringValue) > 0 || Number.isInteger(parseFloat(stringValue))
        ? (newValue = Math.trunc(parseFloat(stringValue)) + 1)
        : (newValue = Math.trunc(parseFloat(stringValue)));

      setStringValue(newValue.toString());
      debounced(newValue);
    }
  };

  React.useEffect(() => {
    if (calculatedValue) {
      setStringValue((previous) => {
        const newValue = !isNaN(parseFloat(previous))
          ? roundWithPrecision(
              parseFloat(previous) + parseFloat(calculatedValue),
              5
            ).toString()
          : calculatedValue;
        debounced(parseFloat(newValue));
        return newValue;
      });
    }
  }, [calculatedValue, debounced]);

  return (
    <TableCell align="center" className="change-cell">
      {rowType !== 'day' && (
        <Stack direction="row" spacing={0.5}>
          <Button
            label=""
            variant="contained"
            color="error"
            fullWidth={false}
            endIcon={<RemoveIcon />}
            onClick={removeOne}
            disabled={disabled}
          />
          <TextField
            id="test"
            value={stringValue}
            onChange={handleChangeStringValue}
            className="input"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            disabled={disabled}
            autoComplete="off"
          />
          <Button
            label=""
            variant="contained"
            color="success"
            fullWidth={false}
            endIcon={<AddIcon />}
            onClick={addOne}
            disabled={disabled}
          />
        </Stack>
      )}
      {rowType === 'day' && (
        <TextField
          value={stringValue}
          onChange={handleChangeStringValue}
          className="input"
          fullWidth
          disabled={disabled}
          autoComplete="off"
        />
      )}
    </TableCell>
  );
};

export default ChangeCell;
