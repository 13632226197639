import React, { FC } from 'react';
import {
  Collapse,
  Paper,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { SegmentForecastSum } from '../../../utils/constants/forecastSum';
import RoomNightsRow from '../rows/RoomNightsRow';
import AdrRow from '../rows/AdrRow';
import RoomRevenueRow from '../rows/RoomRevenueRow';
import NonRoomRevenueRow from '../rows/NonRoomRevenueRow';
import HotelTables from '../hotelTables/HotelTables';
import CollapsibleCell from '../cells/CollapsibleCell';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectNewSegmentForecast,
  updateNewSegmentForecast,
} from '../../../store/slices/newForecastSlice';
import { selectResortCount } from '../../../store/slices/resortCountSlice';
import { selectNumberOfSelectedDays } from '../../../store/slices/filtersSlice';

interface SegmentTablesProps {
  segmentForcastSum: SegmentForecastSum;
}

const SegmentTable: FC<SegmentTablesProps> = ({ segmentForcastSum }) => {
  const dispatch = useAppDispatch();
  const resortCount = useAppSelector(selectResortCount);
  const numberOfSelectedDays = useAppSelector(selectNumberOfSelectedDays);
  const newSegmentForecast = useAppSelector((state) =>
    selectNewSegmentForecast(state, segmentForcastSum.segment.id)
  );

  const onChange = (
    newValue: number | null,
    target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue'
  ) => {
    dispatch(
      updateNewSegmentForecast({
        newValue,
        target,
        segmentId: segmentForcastSum.segment.id,
      })
    );
  };

  const [open, setOpen] = React.useState<boolean>(false);
  const onClick = () => {
    setOpen(!open);
  };
  
  return (
    <>
      <Stack
        direction="row"
        className={`sticky-column-container ${
          segmentForcastSum.locked ? 'disabled' : ''
        }`}
      >
        <TableContainer
          component={Paper}
          elevation={0}
          className={`paper table collapsible-table sticky-column ${
            open ? 'expanded-paper' : ''
          }`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <CollapsibleCell
                  label={segmentForcastSum.segment.name}
                  collapsible={true}
                  rowType="segment"
                  open={open}
                  onClick={onClick}
                  disabled={segmentForcastSum.locked}
                />
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className={`paper table room-nights-table ${
            open ? 'expanded-paper' : ''
          }`}
        >
          <Table>
            <TableHead>
              <RoomNightsRow
                roomSold={segmentForcastSum.roomSoldSum}
                rowType="segment"
                newForecast={newSegmentForecast}
                onChange={onChange}
                disabled={segmentForcastSum.locked}
              />
            </TableHead>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className={`paper table adr-table ${open ? 'expanded-paper' : ''}`}
        >
          <Table>
            <TableHead>
              <AdrRow
                adr={segmentForcastSum.adrSum}
                rowType="segment"
                newForecast={newSegmentForecast}
                onChange={onChange}
                disabled={segmentForcastSum.locked}
              />
            </TableHead>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className={`paper table room-revenue-table ${
            open ? 'expanded-paper' : ''
          }`}
        >
          <Table>
            <TableHead>
              <RoomRevenueRow
                roomRevenue={segmentForcastSum.roomRevenueSum}
                rowType="segment"
                newForecast={newSegmentForecast}
              />
            </TableHead>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          elevation={0}
          className={`paper table non-room-revenue-table ${
            open ? 'expanded-paper' : ''
          }`}
        >
          <Table>
            <TableHead>
              <NonRoomRevenueRow
                nonRoomRevenue={segmentForcastSum.nonRoomRevenueSum}
                rowType="segment"
                newForecast={newSegmentForecast}
                onChange={onChange}
                disabled={segmentForcastSum.locked}
              />
            </TableHead>
          </Table>
        </TableContainer>
      </Stack>

      <Collapse
        in={open}
        timeout={0}
        mountOnEnter={
          !(resortCount !== null && resortCount <= 5 && numberOfSelectedDays <= 31)
        }
        style={{ minHeight: 'auto' }}
      >
        <HotelTables segmentId={segmentForcastSum.segment.id} />
      </Collapse>
    </>
  );
};

export default SegmentTable;
