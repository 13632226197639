import React, { FC } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, Paper, Typography } from '@mui/material';
import Filters from './Filters';
import { useAppSelector } from '../../store/hooks';
import { selectActiveFilters } from '../../store/slices/filtersSlice';

const FilterSection: FC = () => {
  const activeFilters = useAppSelector(selectActiveFilters);

  const [open, setOpen] = React.useState<boolean>(true);
  const onClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Paper elevation={0} className="section-header">
        <Typography variant="h6" component="h2">
          Filters
        </Typography>
        {!open && (
          <Typography color="primary">
            {activeFilters}
            {activeFilters > 1 ? ` active filters` : ` active filter`}
          </Typography>
        )}
        <IconButton onClick={onClick} color="inherit">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Paper>
      <Collapse in={open} timeout="auto" mountOnEnter>
        <Filters collapse={onClick} />
      </Collapse>
    </>
  );
};

export default FilterSection;
