import { createTheme } from '@mui/material/styles';
import palette from '../palette.module.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: palette.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: palette.secondary,
    },
    info: {
      main: palette.info,
    },
    warning: {
      main: palette.warning,
    },
    action: {
      disabledBackground: '#606060',
      disabled: 'rgba(0, 0, 0, 0.5)',
    },
  },
  typography: {
    fontSize: 12,
  },
});

export default theme;
