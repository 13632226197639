import React, { FC } from 'react';
import {
  Paper,
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ColumnHeader } from '../../../utils/constants/columnHeaders/columnHeader';

interface LoadingTableProps {
  columns: ColumnHeader[];
  rowType: 'total' | 'segment' | 'hotel' | 'day';
  tableType:
    | 'room-nights-table'
    | 'adr-table'
    | 'room-revenue-table'
    | 'non-room-revenue-table';
}

const LoadingTable: FC<LoadingTableProps> = ({
  columns,
  rowType,
  tableType,
}) => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={`paper table ${tableType} 
        ${rowType === 'hotel' ? 'hotel-paper' : ''} 
        ${rowType === 'day' ? 'day-paper' : ''}
      `}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                align="center"
                className={`loading-cell ${
                  column.name === 'Change' ? 'change-cell' : ''
                }`}
              >
                {column.name !== 'Change' ? (
                  <Skeleton animation="wave" width={30} />
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={140}
                    height={24}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default LoadingTable;
