import React, { FC } from 'react';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from '@mui/material';
import RoomNightsRow from '../rows/RoomNightsRow';
import AdrRow from '../rows/AdrRow';
import RoomRevenueRow from '../rows/RoomRevenueRow';
import NonRoomRevenueRow from '../rows/NonRoomRevenueRow';
import { DayForecast } from '../../../utils/constants/forecastSum';
import { format } from 'date-fns';
import CollapsibleCell from '../cells/CollapsibleCell';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectNewDayForecast,
  updateNewDayForecast,
} from '../../../store/slices/newForecastSlice';

interface DayTableProps {
  dayForecast: DayForecast;
}

const DayTable: FC<DayTableProps> = ({ dayForecast }) => {
  const dispatch = useAppDispatch();
  const newDayForecast = useAppSelector((state) =>
    selectNewDayForecast(
      state,
      dayForecast.segment.id,
      dayForecast.resort.id,
      dayForecast.forecast.id
    )
  );

  const onChange = (
    newValue: number | null,
    target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue'
  ) => {
    dispatch(
      updateNewDayForecast({
        newValue,
        target,
        segmentId: dayForecast.segment.id,
        hotelId: dayForecast.resort.id,
        dayId: dayForecast.forecast.id,
      })
    );
  };

  return (
    <Stack
      direction="row"
      className={`sticky-column-container ${
        dayForecast.locked ? 'disabled' : ''
      }`}
    >
      <TableContainer
        component={Paper}
        elevation={0}
        className="paper day-paper table collapsible-table sticky-column "
      >
        <Table>
          <TableBody>
            <TableRow>
              <CollapsibleCell
                label={format(
                  new Date(dayForecast.forecast.date),
                  'EEEE - dd/MM/yyyy'
                )}
                rowType="day"
                disabled={dayForecast.locked}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        component={Paper}
        elevation={0}
        className="paper day-paper table room-nights-table"
      >
        <Table>
          <TableBody>
            <RoomNightsRow
              roomSold={dayForecast.roomSoldSum}
              rowType="day"
              newForecast={newDayForecast}
              onChange={onChange}
              disabled={dayForecast.locked}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        component={Paper}
        elevation={0}
        className="paper day-paper table adr-table"
      >
        <Table>
          <TableBody>
            <AdrRow
              adr={dayForecast.adrSum}
              rowType="day"
              newForecast={newDayForecast}
              onChange={onChange}
              disabled={dayForecast.locked}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        component={Paper}
        elevation={0}
        className="paper day-paper table room-revenue-table"
      >
        <Table>
          <TableBody>
            <RoomRevenueRow
              roomRevenue={dayForecast.roomRevenueSum}
              rowType="day"
              newForecast={newDayForecast}
            />
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        component={Paper}
        elevation={0}
        className="paper day-paper table non-room-revenue-table"
      >
        <Table>
          <TableBody>
            <NonRoomRevenueRow
              nonRoomRevenue={dayForecast.nonRoomRevenueSum}
              rowType="day"
              newForecast={newDayForecast}
              onChange={onChange}
              disabled={dayForecast.locked}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default DayTable;
