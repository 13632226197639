import { ColumnHeader } from './columnHeader';

export const nonRoomRevenueColumnHeaders: ColumnHeader[] = [
  {
    name: 'Final Non Room Rev nx (abs)',
    tooltip:
      'Non room revenue at the equivalent date of the reference year',
  },
  {
    name: 'Final Non Room Rev nx (% rev)',
    tooltip: '"Final Non Room Rev nx (abs)" divided by "Final room revenue nx"',
  },
  {
    name: 'Initial forecast',
    tooltip: 'Predicted value by the algorithm given past values.',
  },
  {
    name: 'Last saved forecast',
    tooltip:
      'Last forecast which has been saved by user. Default value = "Initial forecast"',
  },
  {
    name: 'Change',
    tooltip:
      'Forecasted non room revenue computed with forecasted room sold and forecasted adr',
  },
  {
    name: 'New forecast',
    tooltip: 'Last run forecasted non room revenue (ht)',
  },
  {
    name: 'New forecast (% of room rev)',
    tooltip:
      'non_room_revenue."New forecast"  divided by "room_revenue."New forecast"',
  },
  {
    name: 'Previous Forecast',
    tooltip: 'Last run forecasted non room revenue (ht)',
  },
  {
    name: 'Budget',
    tooltip: 'Non room revenue budget.',
  },
  {
    name: 'New Forecast vs Budget',
    tooltip: 'Ratio between "New forecast" and "Budget"',
  },
];
