import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../layout/Layout';
import Forecast from '../pages/forecast/Forecast';

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Forecast />} />
      </Route>
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );
};

export default PrivateRoutes;
