import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { TotalForecastSum } from '../../utils/constants/forecastSum';
import { totalForecastApi } from '../api/totalForecastApi';

interface ResortCount {
  resortCount: number | null;
}

const initialState: ResortCount = {
  resortCount: null,
};

export const resortCountSlice = createSlice({
  name: 'resortCount',
  initialState,
  reducers: {
    resetResortCount: (state) => {
      state.resortCount = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      totalForecastApi.endpoints.getTotalForecastSum.matchFulfilled,
      (state, action: PayloadAction<TotalForecastSum>) => {
        if (action.payload) {
          state.resortCount = action.payload.resortCount;
        }
      }
    );
  },
});

export const { resetResortCount } = resortCountSlice.actions;

export const selectResortCount = (state: RootState): number | null => {
  return state.resortCount.resortCount;
};
