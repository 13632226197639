import React, { FC } from 'react';
import { toast } from 'react-toastify';
import LockIcon from '@mui/icons-material/Lock';
import {
  AppBar,
  Grid,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import Button from '../common/button/Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectConvertToEur,
  selectConvertToTTC,
  updateConvertToEur,
  updateConvertToTTC,
} from '../../store/slices/moneySlice';
import { selectIsSearching } from '../../store/slices/searchButtonSlice';
import {
  selectUserFullName,
  selectUserIsDrm,
  selectUserIsHeadOf,
} from '../../store/slices/authSlice';
import LockBUModal from '../modals/lockBuModal/LockBUModal';

const Header: FC = () => {
  const dispatch = useAppDispatch();
  const convertToEur = useAppSelector(selectConvertToEur);
  const convertToTTC = useAppSelector(selectConvertToTTC);
  const isSearching = useAppSelector(selectIsSearching);
  const userIsHeadOf = useAppSelector(selectUserIsHeadOf);
  const userIsDrm = useAppSelector(selectUserIsDrm);
  const userEmail = useAppSelector(selectUserFullName);

  const handleVat = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateConvertToTTC(event.target.checked));
    toast.info(
      `The VAT has been changed to : ${event.target.checked ? 'VAT included' : 'VAT excluded'}`
    );
  };

  const handleCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateConvertToEur(event.target.checked));
    toast.info(
      `The currency has been changed to : ${
        event.target.checked ? 'EUR' : 'LOC'
      }`
    );
  };

  /**
   * Lock BU actions
   */
  const [openLockBUModal, setOpenLockBUModal] = React.useState<boolean>(false);

  const handleClickOpenLockBUModal = () => {
    setOpenLockBUModal(true);
  };

  const handleCloseLockBUModal = () => {
    setOpenLockBUModal(false);
  };

  return (
    <>
      <AppBar className="header">
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <Stack direction="row" spacing={2}>
                {(userIsDrm || userIsHeadOf) && (
                  <Button
                    label="Lock BU"
                    color="inherit"
                    startIcon={<LockIcon />}
                    fullWidth={false}
                    onClick={handleClickOpenLockBUModal}
                  />
                )}
                <Stack direction="row" alignItems="center">
                  <Typography variant="button">VAT excluded</Typography>
                  <Switch
                    checked={convertToTTC}
                    onChange={handleVat}
                    disabled={isSearching}
                    className="switch-header"
                  />
                  <Typography variant="button">VAT included</Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Typography variant="button">LOC</Typography>
                  <Switch
                    checked={convertToEur}
                    onChange={handleCurrency}
                    disabled={isSearching}
                    className="switch-header"
                  />
                  <Typography variant="button">EUR</Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h5" component="h1">
                Forecasting Tool
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography variant="button">{userEmail}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <LockBUModal
        open={openLockBUModal}
        onClose={handleCloseLockBUModal}
      ></LockBUModal>
    </>
  );
};

export default Header;
