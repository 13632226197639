import React, { FC } from 'react';
import { Popper, TableRow } from '@mui/material';
import { RoomSold } from '../../../utils/constants/forecastSum';
import ChangeCell from '../cells/ChangeCell';
import NumericCell from '../cells/NumericCell';
import { NewForecast } from '../../../store/slices/newForecastSlice';
import Calculate from '../Calculate';

interface RoomNightsRowProps {
  roomSold: RoomSold;
  rowType: 'total' | 'segment' | 'hotel' | 'day';
  newForecast: NewForecast;
  onChange: (
    newValue: number | null,
    target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue'
  ) => void;
  disabled?: boolean;
}

const RoomNightsRow: FC<RoomNightsRowProps> = ({
  roomSold,
  rowType,
  newForecast,
  onChange,
  disabled = false,
}) => {
  const [change, setChange] = React.useState<string>();
  const [anchorElement, setAnchorElement] =
    React.useState<HTMLTableCellElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLTableCellElement>) => {
    setChange(undefined);
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleApply = (newValue: string) => {
    setChange(newValue);
    handleClose();
  };

  const open = Boolean(anchorElement);

  return (
    <>
      <TableRow>
        <NumericCell value={roomSold.pickup2W} />
        <NumericCell value={roomSold.pickup1W} />
        <NumericCell
          value={roomSold.bob}
          potentialRoom={roomSold.potentialRoom}
        />
        <NumericCell
          value={roomSold.bobLag}
          potentialRoom={roomSold.potentialRoom}
        />
        <NumericCell value={roomSold.bobRatio} percentage />
        <NumericCell
          value={roomSold.lag}
          potentialRoom={roomSold.potentialRoom}
        />
        <NumericCell value={roomSold.trendNWeeks} />
        <NumericCell value={roomSold.lagTrendNWeeks} />
        <NumericCell value={roomSold.lastWeeksRatio} percentage />
        <NumericCell
          value={roomSold.initialForecast}
          potentialRoom={roomSold.potentialRoom}
          precision={1}
        />
        <NumericCell
          value={roomSold.newForecast + roomSold.newForecastLocked}
          potentialRoom={roomSold.potentialRoom}
          precision={1}
        />
        <ChangeCell
          rowType={rowType}
          onChange={onChange}
          target="roomSoldValue"
          disabled={disabled}
          calculatedValue={change}
        />
        <NumericCell
          value={newForecast.roomSoldForecast}
          potentialRoom={roomSold.potentialRoom}
          precision={1}
          onClick={rowType !== 'day' ? (event) => handleOpen(event) : undefined}
        />
        <NumericCell
          value={roomSold.previousForecast}
          potentialRoom={roomSold.potentialRoom}
          precision={1}
        />
        <NumericCell
          value={roomSold.budget}
          potentialRoom={roomSold.potentialRoom}
          precision={0}
        />
        <NumericCell
          value={(newForecast.roomSoldForecast / roomSold.budget) * 100}
          percentage
          precision={0}
        />
      </TableRow>
      <Popper
        open={open}
        anchorEl={anchorElement}
        placement="right"
        className="popper"
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
        ]}
      >
        <Calculate
          onApply={handleApply}
          onClose={handleClose}
          currentValue={newForecast.roomSoldForecast}
          unlockedValue={roomSold.newForecast}
          fixedDaySum={newForecast.roomSoldFixedDaySum}
        />
      </Popper>
    </>
  );
};

export default RoomNightsRow;
