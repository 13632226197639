import React, { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify';

const ToastAlertContainer: FC = () => {
  return (
    <>
      <ToastContainer
        theme="colored"
        position="top-right"
        transition={Slide}
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ToastAlertContainer;
