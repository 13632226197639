import React, { FC } from 'react';
import { Popper, TableRow } from '@mui/material';
import { NewForecast } from '../../../store/slices/newForecastSlice';
import { NonRoomRevenue } from '../../../utils/constants/forecastSum';
import ChangeCell from '../cells/ChangeCell';
import NumericCell from '../cells/NumericCell';
import Calculate from '../Calculate';

interface NonRoomRevenueRowProps {
  nonRoomRevenue: NonRoomRevenue;
  rowType: 'total' | 'segment' | 'hotel' | 'day';
  newForecast: NewForecast;
  onChange: (
    newValue: number | null,
    target: 'adrValue' | 'roomSoldValue' | 'nonRoomRevenueValue'
  ) => void;
  disabled: boolean;
}

const NonRoomRevenueRow: FC<NonRoomRevenueRowProps> = ({
  nonRoomRevenue,
  rowType,
  newForecast,
  onChange,
  disabled = false,
}) => {
  const [change, setChange] = React.useState<string>();
  const [anchorElement, setAnchorElement] =
    React.useState<HTMLTableCellElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLTableCellElement>) => {
    setChange(undefined);
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleApply = (newValue: string) => {
    setChange(newValue);
    handleClose();
  };

  const open = Boolean(anchorElement);

  return (
    <>
      <TableRow>
        <NumericCell value={nonRoomRevenue.lag} />
        <NumericCell value={nonRoomRevenue.lagRatio} percentage />
        <NumericCell value={nonRoomRevenue.initialForecast} precision={3} />
        <NumericCell
          value={nonRoomRevenue.newForecast + nonRoomRevenue.newForecastLocked}
          precision={3}
        />
        <ChangeCell
          rowType={rowType}
          onChange={onChange}
          target="nonRoomRevenueValue"
          disabled={disabled}
          calculatedValue={change}
        />
        <NumericCell
          value={newForecast.nonRoomRevenueForecast}
          precision={3}
          onClick={rowType !== 'day' ? (event) => handleOpen(event) : undefined}
        />
        <NumericCell
          value={
            (newForecast.nonRoomRevenueForecast /
              (newForecast.roomSoldForecast * newForecast.adrForecast)) *
            100
          }
          percentage
          precision={1}
        />
        <NumericCell value={nonRoomRevenue.previousForecast} precision={3} />
        <NumericCell value={nonRoomRevenue.budget} precision={0} />
        <NumericCell
          value={
            (newForecast.nonRoomRevenueForecast / nonRoomRevenue.budget) * 100
          }
          percentage
          precision={0}
        />
      </TableRow>
      <Popper
        open={open}
        anchorEl={anchorElement}
        placement="right"
        className="popper"
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: false,
          },
        ]}
      >
        <Calculate
          onApply={handleApply}
          onClose={handleClose}
          currentValue={newForecast.nonRoomRevenueForecast}
          unlockedValue={nonRoomRevenue.newForecast}
          fixedDaySum={newForecast.nonRoomRevenueFixedDaySum}
        />
      </Popper>
    </>
  );
};

export default NonRoomRevenueRow;
